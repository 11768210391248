import React, { useState, useRef } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  MenuItem,
  Menu,
  makeStyles,
  Box,
  Hidden,
  ClickAwayListener,
  Typography,
  Popper,
  Paper,
  Grow,
  Divider,
} from "@material-ui/core";
import shellIcon from "../../assets/svg-icons/shell.svg";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { FONTS } from "../../utils";
import { AuthReducerActions } from "../../reducers";
import { ICONS, Version } from "../../commons";
import { CookiesDialog } from "../../commons/dialogs/cookies-dialog";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.base.elevation_1,
  },
  grow: {
    flex: 1,
  },
  menuItem: {
    color: theme.palette.common.white,
    ...FONTS.Button3_ShellBold_12,
    "&:hover": {
      backgroundColor: theme.palette.base.elevation_2,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuItemActive: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  menuButton: {
    color: theme.palette.common.white,
  },
  user: {
    color: theme.palette.common.white,
    ...FONTS.Label2_Bold_12,
  },
  userInfoBox: {
    width: "150px",
    marginRight: "24px",
  },
  userInfoDiv: { padding: "18px 12px 12px 12px", ...FONTS.B5_Reg_12 },
  logoutBtn: { padding: "12px", ...FONTS.B4_BOLD_14 },
  versionContainer: { paddingBottom: "12px", marginRight: "12px", ...FONTS.Label1_Bold_8 },
}));

function renderItem(menuItem, activeAlertsCount) {
  if (menuItem.href === "/alerts" && activeAlertsCount !== 0) {
    return (
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <div>{menuItem.label}</div>
        <div
          style={{
            background: "#D42E12",
            marginLeft: "4px",
            width: `${activeAlertsCount > 99 ? "27px" : "20px"}`,
            height: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {activeAlertsCount > 99 ? "99+" : activeAlertsCount}
        </div>
      </div>
    );
  } else {
    return menuItem.label;
  }
}

export const TopNavBar = (props) => {
  const { siteID, MainMenuListJSON, setShowDrawerMenu, menuItemClick, activeAlertsCount } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const userAuth = useSelector((state) => state.auth);
  const classes = useStyles();
  const [auth] = useState(true);
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);
  const guideURL = process.env.REACT_APP_SITEWISE_USERGUIDE_URL

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleUserLogout() {
    dispatch(AuthReducerActions.logout());
  }

  function handleUserLogin() {
    history.push("/");
  };

  return (
    <AppBar position="relative" classes={{ root: classes.root }}>
      <Toolbar>
        <Hidden xsDown>
          <IconButton
            edge="start"
            aria-label="logo"
            style={{ marginRight: "20px" }}
            onClick={() => history.push("/zones")}
          >
            <img src={shellIcon} alt="shell logo" width="34px" height="34px" />
          </IconButton>
        </Hidden>

        <Hidden smUp>
          <IconButton
            edge="start"
            className={classes.menuButton}
            aria-label="menu"
            onClick={() => setShowDrawerMenu(true)}
          >
            <ICONS.Menu />
          </IconButton>
        </Hidden>

        <Hidden xsDown>
          <div style={{ display: "flex", alignSelf: "stretch" }}>
            {MainMenuListJSON.map((menuItem) => {
              const isActiveLink = location.pathname.includes(menuItem.href);
              return (
                <MenuItem
                  disabled={siteID === null && menuItem.href !== "/sites" ? true : false}
                  key={menuItem.label}
                  disableRipple
                  className={`${classes.menuItem} ${isActiveLink ? classes.menuItemActive : ""}`}
                  onClick={menuItemClick(menuItem.href)}
                >
                  {renderItem(menuItem, activeAlertsCount)}
                </MenuItem>
              );
            })}
          </div>
        </Hidden>

        <div className={classes.grow}></div>
        {auth && (
          <Hidden xsDown>
            <Box display="flex" alignItems="center">
              <div className={classes.user}>{userAuth.userInfo?.name}</div>
              <div>
                <IconButton
                  ref={anchorRef}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <ICONS.PersonOutline />
                </IconButton>
                {userAuth.isLoggedIn ? (
                  <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: "left bottom",
                        }}
                      >
                        <Paper className={classes.userInfoBox}>
                          <ClickAwayListener onClickAway={handleToggle}>
                            <div className={classes.root}>
                              <Typography component="p" align="right">
                                <div className={classes.userInfoDiv}>
                                  <div>{userAuth.userInfo?.role}</div>
                                  {userAuth.userInfo.category !== "None" &&
                                    <div>{userAuth.userInfo?.category}</div>
                                  }
                                  {userAuth.userInfo.userType !== "None" &&
                                    <div>{userAuth.userInfo?.userType}</div>}
                                  <div><a style={{ color: "white" }} target="_blank" rel="noreferrer" href={guideURL}>SitewiseUserGuide </a> </div>
                                </div>
                                <Version
                                  title="Sitewise Version"
                                  className={classes.versionContainer}
                                />
                                <Divider />
                                <Button
                                  size="small"
                                  className={classes.logoutBtn}
                                  onClick={handleUserLogout}
                                  color="primary"
                                >
                                  Log Out
                                </Button>
                              </Typography>
                            </div>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                ) : (
                  <Menu
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={handleToggle}
                  >
                    <MenuItem onClick={handleUserLogin}>Login</MenuItem>
                  </Menu>
                )}
              </div>
            </Box>
          </Hidden>
        )}
      </Toolbar>

      <CookiesDialog />

    </AppBar>
  );
};
