import { loadModules } from "esri-loader";
import { MapWidgetTmpl } from "./map-widget-tmpl";
import { store } from "../../../../store";
import { MapUtils } from "./map-utils";
import { pointerMoveEventHandler } from "./util-fns/pointer-move-event-handler";
import {
  ZONE_REDUCER_TYPES,
  ZonesReducerActions,
  GlobalReducerActions,
  plotPlanImageLayerJson,
} from "../../../../reducers";
import { MapUtilsV2 } from "../../../../commons/map";
import { onUserDeviceClick } from "../../../alerts/components/alerts-map/util-fns/pointer-move-event-handler";

const MAP_CONFIG = {
  SKETCH_TOOLS: {
    CIRCLE: "circle",
    RECTANGLE: "rectangle",
    POLYGON: "polygon",
  },
  DEFAULT_ZOOM: 16,
};

export const GRAPHICS_ACTIONS = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  CREATE_FROM_TEMPLATE: "CREATE_FROM_TPL",
  NONE: "NONE",
};

const GRAPHICS_LAYERS_ID = {
  ZONES: "ZONES_GRAPHICS_LAYER",
  LABELS: "ZONES_LABEL_LAYER",
  HEADCOUNT: "ZONES_HEADCOUNT_LAYER",
  USER_DEVICES: "USER_DEVICES_LAYER",
  MAP_VECTOR_TILE: "MAP_VECTOR_TILE",
  MAP_IMAGE_LAYER: "MAP_IMAGE_LAYER",
};

let ArcGISMap,
  MapView,
  GraphicsLayer,
  Sketch,
  SketchViewModel,
  Graphic,
  Polygon,
  Point,
  geometryEngine,
  webMercatorUtils,
  VectorTileLayer,
  EsriConfig,
  TileLayer,
  MapImageLayer,
  ImageryLayer;

export class ZoneMap {
  constructor({ onMapLoadCb, viewDevicesEnabled, viewAlertsEnabled }) {
    this.modulesLoaded = false;
    this.map = null;
    this.mapView = null;
    this.sketchVM = null;
    this.onMapLoadCb = onMapLoadCb;
    this.selectedGraphic = null;
    this.currentGraphic = null;
    this.graphicSketchAction = GRAPHICS_ACTIONS.NONE;
    this.store = store;
    this.mapUtils = null;
    this.viewDevicesEnabled = viewDevicesEnabled;
    this.viewAlertsEnabled = viewAlertsEnabled;
    this.state = {
      showGraphicLabel: false,
      showSketchTools: false,
      formOpen: false,
    };

    this.loadModulesAndInitMap();
  }

  async loadModulesAndInitMap() {
    [
      ArcGISMap,
      MapView,
      EsriConfig,
      GraphicsLayer,
      Graphic,
      Polygon,
      Sketch,
      SketchViewModel,
      Point,
      geometryEngine,
      webMercatorUtils,
      VectorTileLayer,
      TileLayer,
      MapImageLayer,
      ImageryLayer,
    ] = await loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/config",
        "esri/layers/GraphicsLayer",
        "esri/Graphic",
        "esri/geometry/Polygon",
        "esri/widgets/Sketch",
        "esri/widgets/Sketch/SketchViewModel",
        "esri/geometry/Point",
        "esri/geometry/geometryEngine",
        "esri/geometry/support/webMercatorUtils",
        "esri/layers/VectorTileLayer",
        "esri/layers/TileLayer",
        "esri/layers/MapImageLayer",
        "esri/layers/ImageryLayer",
      ],
      {
        css: true,
      }
    );

    this.modulesLoaded = true;
    if (process.env.REACT_APP_ARCGIS_MAP_KEY) {
      EsriConfig.apiKey = process.env.REACT_APP_ARCGIS_MAP_KEY;
    }

    this.map = new ArcGISMap({
      basemap: "satellite",
    });

    const site = this.store.getState().globalState.site;
    const siteName = site?.name.toLowerCase();
    const currentSiteLayer = plotPlanImageLayerJson[siteName];

    if (currentSiteLayer?.show) {
      if (currentSiteLayer?.trustedServers) {
        EsriConfig.request.trustedServers.push(currentSiteLayer.trustedServers);
      }
      currentSiteLayer.layers.forEach((index) => {
        const layerType = index.layer;
        this.validateLayerType(layerType, this.map, index.layerProperties);
      });
    }

    this.mapView = new MapView({
      container: "map-container",
      map: this.map,
      highlightOptions: {
        color: [255, 255, 0, 1],
        haloOpacity: 0.9,
        fillOpacity: 0.2,
      },
      popup: {
        collapseEnabled: false,
        actions: [],
        visibleElements: {
          closeButton: true,
        },
        dockOptions: {
          buttonEnabled: false,
          breakpoint: false,
        },
      },
      ...MapUtilsV2.getSubSiteCenter(site),
      zoom: site.subSites && site.subSites[0] ? site.subSites[0].zoom : 3,
      constraints: {
        maxZoom: site.maxZoom ? site.maxZoom : 3,
        minZoom: site.minZoom ? site.minZoom : 20,
      },
    });

    if (site.subSites[0]) {
      this.mapView.rotation = site.subSites[0].rotation;
    }

    this.mapUtils = new MapUtils(this.map, this.mapView);

    this.mapView.ui.components = [];

    MapUtilsV2.addDefaultWidgets(this.mapView);

    this.mapView.when(() => {
      this.onMapLoadCb &&
        this.onMapLoadCb(
          this.renderMap,
          this.store.getState().zonesScreen.zones,
          this.store.getState().globalState.mapUserDevices.list
        );
      this.mapUtils.addCustomWidgets(this.labelClickCb, this.cancelCreateGraphic);

      this.mapView.on("click", this.clickEvents);

      if ("ontouchstart" in window) {
        //not use
      } else {
        pointerMoveEventHandler(
          this.mapView,
          this.sketchVM,
          this.sketch,
          this.state,
          this.currentGraphic,
          this.store,
          GRAPHICS_LAYERS_ID,
          this.viewAlertsEnabled
        );
      }

      this.mapView.on("key-down", this.keyDownEvent);
    });
  }

  validateLayerType(layerType, mapContext, layerContext) {
    let Layer;
    if (layerType === "ImageryLayer") {
      Layer = new ImageryLayer(layerContext);
    } else if (layerType === "MapImageLayer") {
      Layer = new MapImageLayer(layerContext);
    } else if (layerType === "VectorTileLayer") {
      Layer = new VectorTileLayer(layerContext);
    } else {
      Layer = new TileLayer(layerContext);
    }
    mapContext.add(Layer);
  }

  identifyWithinZoneAndNotWithinZone(unique){
    if (unique?.length > 1) {
      const onlyWithinZoneDevices = unique.filter((item) => {
        return item?.zone;
      });
      this.goToSiteLocation(onlyWithinZoneDevices[0]?.device?.status?.location);
    } else if (unique?.length > 0) {
      this.goToSiteLocation(unique[0].device?.status?.location);
    }
  }
  
    filterRecentAlertDevice(activeAlerts){
      const unique = [
        ...new Map(
          activeAlerts.map((item) => [
            item.device?.id ? item["device"]["id"] : 0,
            item,
          ])
        ).values(),
      ];
      this.identifyWithinZoneAndNotWithinZone(unique);
    }
    goToRecentAlertDevice() {
      const { activeAlerts } = this.store.getState().globalState?.alerts;
      if (activeAlerts.length) {
        this.filterRecentAlertDevice(activeAlerts);
      }
    }

  clickEvents = async (event) => {
    event.stopPropagation();
    if (this.sketchVM.state === "active") {
      return;
    }

    if (this.store.getState().zonesScreen.mapEditShapeInProgress) {
      this.sketch.update(this.currentGraphic);
      return;
    }

    const isOnUserClick = await onUserDeviceClick(
      event,
      this.mapView,
      this.store,
      GRAPHICS_LAYERS_ID,
      this.viewAlertsEnabled
    );

    if (isOnUserClick) {
      return;
    }

    const selectedGraphics = [];
    const layer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.ZONES);
    layer.graphics.forEach((graphic) => {
      if (graphic.geometry.contains(event.mapPoint)) {
        var area = geometryEngine.geodesicArea(
          geometryEngine.simplify(graphic.geometry),
          "square-meters"
        );
        graphic.attributes.area = area;
        selectedGraphics.push(graphic);
      }
    });

    selectedGraphics.sort((graphicA, graphicB) =>
      graphicA.attributes.area > graphicB.attributes.area ? 1 : -1
    );

    const graphic = selectedGraphics[0];

    if (graphic) {
      this.selectedGraphic = graphic;
      MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(graphic, true);
      this.gotToGraphic(graphic);
      this.store.dispatch({
        type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_BY_GRAPHIC_ID,
        payload: graphic.attributes.id,
      });
    }
  };

  keyDownEvent = (event) => {
    if (event.key === "Escape") {
      this.cancelCreateGraphic();
    }
  };

  labelClickCb = () => {
    const editing = this.store.getState().zonesScreen.editingForm;
    if (editing) {
      this.store.dispatch({ type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_DIALOG_DISPLAY, payload: true });
    } else {
      this.mapView.goTo({
        zoom: MAP_CONFIG.DEFAULT_ZOOM,
      });
      MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
      this.store.dispatch({ type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE, payload: null });
      this.selectedGraphic = null;
      const filters = this.store.getState().globalState.mapUserDevices.filters;
      this.store.dispatch(GlobalReducerActions.getUserDevicesWithAppliedFilters(filters, false));
      this.rerenderUserDevices();
    }
  };

  renderMap = (zones, userDevices) => {
    if (!this.modulesLoaded) {
      return;
    }

    const zonesGraphicsLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.ZONES,
    });

    const zonesHeadcountLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.HEADCOUNT,
    });

    const zonesLabelsGraphicLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.LABELS,
    });

    const userDevicesGraphicLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.USER_DEVICES,
    });

    this.map.addMany([
      zonesGraphicsLayer,
      zonesHeadcountLayer,
      zonesLabelsGraphicLayer,
      userDevicesGraphicLayer,
    ]);

    if (zones.length) {
      this.renderZones(zones);
    }

    if (userDevices.length) {
      this.renderUserDevices(userDevices);
    }
  };

  resetZonesMapLayer = () => {
    const toClear = [
      GRAPHICS_LAYERS_ID.ZONES,
      GRAPHICS_LAYERS_ID.HEADCOUNT,
      GRAPHICS_LAYERS_ID.LABELS,
    ];

    for (const layerID of toClear) {
      const layer = MapUtilsV2.getLayerById(this.map, layerID);
      layer && layer.graphics.removeAll();
    }

    this.mapUtils.hideAllActionsBttns(false);
  };

  resetUserDevicesMapLayer = async () => {
    const toClearLayers = [GRAPHICS_LAYERS_ID.USER_DEVICES];
    for (const layerID of toClearLayers) {
      const layer = MapUtilsV2.getLayerById(this.map, layerID);
      layer && layer.graphics.removeAll();
    }

    this.mapUtils.hideAllActionsBttns(false);
  };

  async rerenderUserDevices() {
    const layer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.USER_DEVICES);
    const allUserDevices = layer.graphics;

    allUserDevices.forEach((userDeviceGraphic) => {
      const { alerts } = userDeviceGraphic.attributes;
      if (alerts.length === 0) {
        userDeviceGraphic.visible =
          this.store.getState().globalState.mapUserDevices.filters.filtersApplied ||
          !!this.store.getState().zonesScreen.selectedZone;
      } else {
        userDeviceGraphic.visible = this.viewAlertsEnabled;
      }
    });
  }

  async renderZones(zones) {
    if (!this.modulesLoaded) {
      return;
    }

    if (this.store.getState().zonesScreen.mapEditShapeInProgress || this.state.formOpen) {
      return;
    }

    const zonesGraphicsLayer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.ZONES);
    const zonesHeadcountLayer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.HEADCOUNT);
    const zonesLabelsGraphicLayer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.LABELS);

    if (!zonesGraphicsLayer && !zonesHeadcountLayer && !zonesLabelsGraphicLayer) {
      return;
    }

    zonesLabelsGraphicLayer.visible = this.viewDevicesEnabled;
    zonesHeadcountLayer.visible = this.viewDevicesEnabled;

    this.resetZonesMapLayer();

    zones.forEach(async (zone) => {
      const rings = zone.polygonPoints.map((p) => {
        return [p.longitude, p.latitude];
      });

      const polygon = new Polygon({
        rings: rings,
      });

      let zoneColourWithoutAlpha;
      if (zone.displayColour) {
        zoneColourWithoutAlpha = [
          zone.displayColour["red"],
          zone.displayColour["green"],
          zone.displayColour["blue"],
        ];
      } else {
        zoneColourWithoutAlpha = [255, 0, 0];
      }

      const textSymbol = {
        type: "text",
        color: "black",
        xoffset: 0,
        yoffset: -4,
        text: zone.headcount,
        font: {
          size: 12,
          weight: "bold",
        },
      };

      const polygonFillSymbol = {
        type: "simple-fill",
        color: [...zoneColourWithoutAlpha, 0.3],
        style: "solid",
        outline: {
          color: {
            r: zoneColourWithoutAlpha[0],
            g: zoneColourWithoutAlpha[1],
            b: zoneColourWithoutAlpha[2],
            a: 1,
          },
          width: 2,
        },
      };

      var markerSymbol = {
        style: "circle",
        size: 20,
        type: "simple-marker",
        color: [...zoneColourWithoutAlpha, 1],
        outline: {
          width: 0,
        },
      };

      var polygonGraphic = new Graphic({
        geometry: webMercatorUtils.geographicToWebMercator(polygon),
        symbol: polygonFillSymbol,
        attributes: {
          ...zone,
          type: "ZONE",
        },
      });

      var point = new Point(polygonGraphic.geometry.centroid);

      polygonGraphic.attributes = {
        ...zone,
        type: "ZONE",
      };

      var pointGraphic = new Graphic({
        geometry: point,
        symbol: markerSymbol,
      });

      var textGraphic = new Graphic({
        geometry: point,
        symbol: textSymbol,
      });

      zonesHeadcountLayer.add(pointGraphic);
      zonesLabelsGraphicLayer.add(textGraphic);
      zonesGraphicsLayer.add(polygonGraphic);
    });

    await this.addSketchToGraphicLayer(zonesGraphicsLayer);
  }

  async renderUserDevices(userDevices) {
    if (!this.modulesLoaded) {
      return;
    }

    if (this.store.getState().zonesScreen.mapEditShapeInProgress || this.state.formOpen) {
      return;
    }

    const userDevicesGraphicLayer = MapUtilsV2.getLayerById(
      this.map,
      GRAPHICS_LAYERS_ID.USER_DEVICES
    );

    if (!userDevicesGraphicLayer) {
      return;
    }

    this.resetUserDevicesMapLayer();

    MapUtilsV2.renderUserDevicesOnMap({ userDevices, Point, Graphic, userDevicesGraphicLayer });

    this.rerenderUserDevices();
  }

  addSketchToGraphicLayer = async (layer) => {
    // SKETCH
    this.sketchVM = new SketchViewModel({
      updateOnGraphicClick: false,
      layer: layer,
      view: this.mapView,
    });

    this.sketch = new Sketch({
      creationMode: "update",
      availableCreateTools: ["polygon", "rectangle", "circle", "move", "transform", "reshape"],
      viewModel: this.sketchVM,
      visibleElements: {
        selectionTools: {
          "lasso-selection": false,
          "rectangle-selection": false,
        },
      },
    });

    // SKETCH EVENTS
    this.sketch.on("create", async (event) => {
      if (event.state === "start") {
        if (this.graphicSketchAction === GRAPHICS_ACTIONS.NONE) {
          this.graphicSketchAction = GRAPHICS_ACTIONS.CREATE;
        }
      }

      if (event.state === "complete") {
        this.currentGraphic = event.graphic;
        if (event.tool === MAP_CONFIG.SKETCH_TOOLS.CIRCLE) {
          this.currentGraphic.attributes = {
            shape: "Circle",
          };
        } else if (
          event.tool === MAP_CONFIG.SKETCH_TOOLS.RECTANGLE ||
          event.tool === MAP_CONFIG.SKETCH_TOOLS.POLYGON
        ) {
          this.currentGraphic.attributes = {
            shape: "Polygon",
          };
        }
      }
    });

    this.sketch.on("update", (event) => {
      console.log("update", event);

      if (event.state === "start") {
        this.store.dispatch({
          type: ZONE_REDUCER_TYPES.SET_MAP_EDIT_SHAPE_IN_PROGRESS,
          payload: true,
        });
        this.mapUtils.updateSketchHelperTxt(
          true,
          "Edit by dragging the dots and click 'Done' when finished."
        );
        this.mapUtils.disableSketchOptions(true);
        const labelsPointsLayer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.LABELS);
        const headcountLayer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.HEADCOUNT);

        if (this.graphicSketchAction === GRAPHICS_ACTIONS.CREATE_FROM_TEMPLATE) {
          // hide del tool panel in CREATE_FROM_TEMPLATE
          this.mapUtils.setShowSketchInfoPanel(false);
          labelsPointsLayer.visible = false;
          headcountLayer.visible = false;
        } else if (this.graphicSketchAction === GRAPHICS_ACTIONS.UPDATE) {
          labelsPointsLayer.visible = false;
          headcountLayer.visible = false;
        }

        if (
          this.graphicSketchAction === GRAPHICS_ACTIONS.CREATE ||
          this.graphicSketchAction === GRAPHICS_ACTIONS.CREATE_FROM_TEMPLATE
        ) {
          const saveBttn = MapWidgetTmpl.getGraphicSaveBttn();
          this.mapView.ui.add(saveBttn, "manual");
          saveBttn.style.display = "block";
          saveBttn.onclick = () => {
            this.sketchVM.complete();

            if (this.graphicSketchAction === GRAPHICS_ACTIONS.CREATE) {
              this.store.dispatch({
                type: ZONE_REDUCER_TYPES.SET_SELECTED_MAP_GRAPHIC,
                payload: this.currentGraphic,
              });
              this.store.dispatch({
                type: ZONE_REDUCER_TYPES.SET_CREATE_ZONE_FORM_DISPLAY,
                payload: true,
              });
            } else {
              this.store.dispatch({
                type: ZONE_REDUCER_TYPES.SET_SELECTED_MAP_GRAPHIC,
                payload: this.currentGraphic,
              });
            }

            this.state.formOpen = true;
            // Hide Tools
            this.graphicSketchAction = GRAPHICS_ACTIONS.NONE;
            this.setShowSketchTools(false);
          };
        } else if (this.graphicSketchAction === GRAPHICS_ACTIONS.UPDATE) {
          const updateBttn = MapWidgetTmpl.addGraphicUpdateBttn();
          const cancelBttn = MapWidgetTmpl.getGraphicCancelBttn(this.cancelCreateGraphic);
          this.mapView.ui.add(updateBttn, "manual");
          this.mapView.ui.add(cancelBttn, "manual");
          updateBttn.style.display = "block";
          cancelBttn.style.display = "block";
          updateBttn.onclick = this.updateGraphic;
        }
      }

      if (event.state === "active") {
        this.store.dispatch({
          type: ZONE_REDUCER_TYPES.SET_MAP_EDIT_SHAPE_IN_PROGRESS,
          payload: true,
        });
        this.currentGraphic = event.graphics[0];
      }

      if (event.state === "complete") {
        this.currentGraphic = event.graphics[0];
        MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
        this.mapUtils.disableSketchOptions(false);
      }
    });

    this.sketch.on("delete", () => {
      this.mapUtils.updateSketchHelperTxt(true);
      this.mapUtils.disableSketchOptions(false);
      this.mapUtils.hideAllActionsBttns();
    });
  };

  selectZoneAndZoom(zoneData, toUpdateSelectedZone = true) {
    this.map.layers.forEach(async (layer) => {
      const graphics = layer.graphics;
      graphics &&
        graphics.forEach(async (graphic) => {
          if (graphic.attributes && graphic.attributes.id === zoneData.id) {
            this.selectedGraphic = graphic;
            if (toUpdateSelectedZone) {
              this.store.dispatch({
                type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_BY_GRAPHIC_ID,
                payload: graphic.attributes.id,
              });
            }

            MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(graphic, true);
            await this.gotToGraphic(graphic);
          }
        });
    });
  }

  selectZone(zoneData) {
    this.map.layers.forEach(async (layer) => {
      const graphics = layer.graphics;
      graphics.forEach(async (graphic) => {
        if (graphic.attributes && graphic.attributes.id === zoneData.id) {
          this.selectedGraphic = graphic;
          this.store.dispatch({
            type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_BY_GRAPHIC_ID,
            payload: graphic.attributes.id,
          });
        }
      });
    });
  }

  unselectZone() {
    this.selectedGraphic = null;
    this.mapView.goTo({
      zoom: MAP_CONFIG.DEFAULT_ZOOM,
    });
    MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
    this.rerenderUserDevices();
  }

  setFormOpen(val) {
    this.state.formOpen = val;
  }


  async goToSiteLocation(site) {
    if (site?.latitude && site?.longitude) {
      const { latitude, longitude } = site;
      var opts = {
        duration: 1000,
        easing: "ease-in-out",
      };
      if (this.mapView) {
        try {
          await this.mapView.goTo(
            {
              center: [longitude, latitude],
              zoom: site.zoom ?? 16,
              rotation: site.rotation ?? 0,
            },
            opts
          );
        } catch (error) {
          console.log(error);
        }
      }
    }
  }


  async gotToGraphic(graphic) {
    try {
      var opts = {
        duration: 1500,
        easing: "ease-out",
      };
      await this.mapView.goTo(
        {
          target: graphic,
        },
        opts
      );
      this.rerenderUserDevices();
    } catch (error) {
      console.log(error);
    }
  }

  showHideAllUserDevices = (toShow) => {
    this.rerenderUserDevices();
  };

  setShowSketchTools = (toShow) => {
    if (toShow) {
      this.mapUtils.updateSketchHelperTxt(true);
      this.mapUtils.updateCancelBttnWidget(true);
      this.mapView.ui.add(this.sketch, "top-left");

      this.mapView.focus();
    } else {
      this.mapUtils.updateSketchHelperTxt(false);
      this.mapView.ui.remove(this.sketch);
      this.mapUtils.hideAllActionsBttns();
      this.mapUtils.updateCancelBttnWidget(false);
    }

    this.store.dispatch({
      type: ZONE_REDUCER_TYPES.SET_MAP_EDIT_SHAPE_IN_PROGRESS,
      payload: toShow,
    });
  };

  cancelCreateGraphic = (selectedZoneNull = true) => {
    const headCount = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.HEADCOUNT);
    const labelsPoints = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.LABELS);
    if (this.graphicSketchAction === "UPDATE") {
      this.selectedGraphic.visible = true;
      if (this.currentGraphic) {
        this.sketchVM.layer.remove(this.currentGraphic);
        this.currentGraphic = null;
      }
      this.sketchVM.layer.remove(this.sketchVM.updateGraphics.items[0]);
      headCount.visible = true;
      labelsPoints.visible = true;
    } else if (this.graphicSketchAction === "CREATE_FROM_TPL") {
      if (this.currentGraphic) {
        this.sketchVM.layer.remove(this.currentGraphic);
        this.currentGraphic = null;
      }
      this.sketchVM.layer.remove(this.sketchVM.updateGraphics.items[0]);
      this.store.dispatch({ type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_FROM_TPL_FORM, payload: false });
      this.store.dispatch({ type: ZONE_REDUCER_TYPES.SET_SHOW_ZONES_TPL_TABLE, payload: false });
      this.store.dispatch({
        type: ZONE_REDUCER_TYPES.SET_ZONES_VIEW_PAGE_FILTER,
        payload: "active",
      });

      this.graphicSketchAction = GRAPHICS_ACTIONS.NONE;
    } else {
      if (this.currentGraphic) {
        this.sketchVM.layer.remove(this.currentGraphic);
        this.currentGraphic = null;
      }
    }
    this.store.dispatch({
      type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
      payload: false,
    });
    this.mapUtils.disableSketchOptions(false);
    this.setShowSketchTools(false);
    this.mapUtils.hideAllActionsBttns();
    this.sketchVM.cancel();
    if (selectedZoneNull) {
      this.store.dispatch({ type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE, payload: null });
    }
  };

  editGraphic = async (zoneData, actionType = GRAPHICS_ACTIONS.CREATE) => {
    this.cancelCreateGraphic(false);
    this.graphicSketchAction = actionType;
    this.selectZoneAndZoom(zoneData, false);
    this.setShowSketchTools(true);
    MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
    if (actionType === GRAPHICS_ACTIONS.CREATE_FROM_TEMPLATE) {
      this.sketch.update(this.selectedGraphic.clone());
    } else {
      this.sketch.update(this.selectedGraphic.clone());
      this.selectedGraphic.visible = false;
    }
  };

  updateGraphic = () => {
    this.store.dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_MAP_GRAPHIC,
      payload: this.currentGraphic,
    });
    this.sketch.complete();
    const graphic = this.currentGraphic;
    this.store.dispatch(ZonesReducerActions.updateZone(graphic));
    this.graphicSketchAction = GRAPHICS_ACTIONS.NONE;
    MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
    this.setShowSketchTools(false);
  };

  showHideLayerLogic(graphicID) {
    const layer = MapUtilsV2.getLayerById(this.map, graphicID);
    if (layer) {
      layer.visible = !layer.visible;
    }
  }

  showHidePlotPlan(graphicID) {
    this.showHideLayerLogic(graphicID);
  }

  showHideMapImageLayer(graphicID) {
    this.showHideLayerLogic(graphicID);
  }
}
