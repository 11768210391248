import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Button, IconButton } from "@material-ui/core";
import { parseUtcToLocal, gasTypes, FONTS } from "../../../../../../utils";
import {
  AlertsReducerActions,
  ALERTS_REDUCER_TYPES,
  GLOBAL_REDUCER_TYPES,
} from "../../../../../../reducers";
import { UserPermissionContext, DeviceIdWithBatteryIcon, ICONS } from "../../../../../../commons";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    width: "100%",
  },
  cardHeader: {
    color: theme.palette.common.black,
    padding: "5px",
    display: "flex",
    justifyContent: "space-between",
    textDecoration: "underline",
  },
  cardTitle: {
    whiteSpace: "no-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100px",
    ...FONTS.Label2_Bold_12,
  },
  cardTitleTime: {
    ...FONTS.B5_Reg_12,
  },
  zoneName: {
    ...FONTS.Label1_ShellBook_24,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    marginBottom: "10.8px",
  },
  paper: { width: "100%", height: "100%" },
  usersInfo: {
    display: "flex",
    marginBottom: "30px",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "12px",
  },
  content: {
    padding: "10px",
  },
  plotClearBttn: {
    color: "black",
    backgroundColor: "#D42E12",
    marginLeft: "12px",
  },
  contentGasLevel: {
    ...FONTS.B5_Bold_12,
    letterSpacing: "1px",
  },
  alertDeviceInfo: {
    fontSize: "12px",
    lineHeight: "109%",
    marginBottom: "17px",
  },
  peopleContainer: {
    height: "60px",
  },
  peopleCategory: {
    float: "left",
    width: "25%",
  },
  peopleLabel: {
    display: "block",
    minHeight: "22px",
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: "10px",
    letterSpacing: "0.02em",
  },
  people: {
    ...FONTS.H4_Bold_24,
  },
}));

function MuteIcon(props) {
  const { isMuted, alertID } = props;
  const dispatch = useDispatch();

  return (
    <IconButton
      onClick={() => {
        dispatch({
          type: GLOBAL_REDUCER_TYPES.SET_MUTED_ALERTS_IDS,
          payload: alertID,
        });
      }}
    >
      {isMuted ? <ICONS.VolumeOff /> : <ICONS.VolumeUp />}
    </IconButton>
  );
}

export const DevicePersonalAlertCard = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  //Permissions
  const { Permissions } = useContext(UserPermissionContext);
  const plotClearAlerts = useSelector((state) => state.globalState.alerts.plotClear);
  const allDevices = useSelector((state) => state.devicesScreen.devices);

  const { alert, clearAlert, onLocateSiteCb } = props;

  const { alertType } = alert;
  let bgColor = "";
  let content = "";

  switch (alertType) {
    case "GasDetected":
      const { device } = alert;
      bgColor = "#FF9400";
      if (device) {
        content = gasTypes(device?.status?.sensors, true)
          .split(",")
          .map((gas, i) => (
            <div key={i} className={classes.contentGasLevel}>
              {gas}
            </div>
          ));
      }
      break;
    case "ManDown":
      bgColor = "#EEEEEE";
      break;

    case "SOS":
      bgColor = "#EEEEEE";
      break;
    default:
      bgColor = "#EEEEEE";
    //code block
  }

  function onClickTriggerPlotClear() {
    dispatch(AlertsReducerActions.plotClear(alert.zone.id));
  }

  function renderAlertDeviceInfo() {
    let alertInfo = null;
    const alertDevice = allDevices.filter((device) => device.id === alert.device?.id)[0];
    if (alertDevice) {
      alertInfo = (
        <div className={classes.alertDeviceInfo}>
          Triggered by: {alertDevice.name} {alert.createdBy} &nbsp;
          <div style={{ display: "inline-block" }}>
            <DeviceIdWithBatteryIcon
              manufacturerId={`#${alertDevice.manufacturerId}`}
              level={alertDevice.status.battery.internalLevel}
              isOnline={alertDevice.status.isOnline}
            />
          </div>
        </div>
      );
    }
    return alertInfo;
  }

  return (
    <div className={classes.cardWrapper}>
      <div
        onClick={() => {
          onLocateSiteCb && onLocateSiteCb(alert.device?.status?.location, true);
        }}
        className={classes.cardHeader}
        style={{ cursor: "pointer", backgroundColor: bgColor }}
      >
        <span className={classes.cardTitle}>{alert.alertTitle}</span>
        <span className={classes.cardTitleTime}>{parseUtcToLocal(alert.createdTimestamp)}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.zoneName}>
          <span>
            {alert && alert.zone
              ? `${alert.zone.name} (${alert.zone.headcount})`
              : `{Not in any zone}`}
          </span>
          <MuteIcon isMuted={alert.muted} alertID={alert.id} />
        </div>
        {renderAlertDeviceInfo()}
        <div>{content}</div>
        <div className={classes.actions}>
          {Permissions.Manage_Alerts ? (
            <Button
              variant="outlined"
              onClick={() => {
                dispatch({ type: ALERTS_REDUCER_TYPES.SET_SELECTED_CURRENT_ALERT, payload: alert });
                clearAlert();
              }}
            >
              CLEAR
            </Button>
          ) : null}
          {Permissions.Manage_Alerts &&
          alert.zone?.id &&
          plotClearAlerts.indexOf(alert.zone.name) === -1 ? (
            <Button
              variant="contained"
              className={classes.plotClearBttn}
              onClick={onClickTriggerPlotClear}
            >
              Trigger Evacuation
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
