import React from "react";
import { makeStyles } from "@material-ui/styles";
import { MultiSiteSelector } from "../../../../commons/multi-site-selector/multi-site-selector";
import { PlotPlan } from "../../../../commons/map/widgets/plot-plan/plot-plan";
import { FullMapWidget, HomeMapWidget } from "../../../../commons";
import { useDispatch, useSelector } from "react-redux";
import { ALERTS_REDUCER_TYPES, plotPlanImageLayerJson } from "../../../../reducers";

const useStyles = makeStyles(() => ({
  mapContainer: {
    width: "100%",
    height: "100%",
  },
}));

export const AlertsMapContainer = (props) => {
  const classes = useStyles();
  const { onLocateSiteCb, showHidePlotPlan, showHideMapImageLayer } = props;
  const dispatch = useDispatch();
  const site = useSelector((state) => state.globalState.site);
  const siteName = site?.name.toLowerCase();
  const mainSite = site?.subSites.length ? site?.subSites[0] : "";
  const mapView = useSelector((state) => state.alertsScreen.mapView);
  const showPlotPlan = plotPlanImageLayerJson[siteName]?.show;

  return (
    <>
      <div id="alerts-map-container" className={classes.mapContainer} />
      <MultiSiteSelector site={site} onLocateSiteCb={onLocateSiteCb} />
      {mainSite ?(<HomeMapWidget
        homeLocation={mainSite}
        onLocateSiteCb={
          onLocateSiteCb
        }
      />):null}
      {showPlotPlan && (
        <PlotPlan
          showHidePlotPlan={showHidePlotPlan}
          showHideMapImageLayer={showHideMapImageLayer}
        />
      )}
      <FullMapWidget
        isSelected={mapView === "ONLY_MAP"}
        mapView={mapView}
        onClickCb={(_mapView) => {
          dispatch({
            type: ALERTS_REDUCER_TYPES.SET_ALERTS_PAGE_MAP_VIEW,
            payload: _mapView === "ONLY_MAP" ? "LARGE_MAP" : "ONLY_MAP",
          });
        }}
      />
    </>
  );
};
