import {
  ZonesAPI,
  ZoneCategoriesAPI,
  SiteLocationsAPI,
  AlertsAPI,
  DashboardMessagesAPI,
  DevicesAPI,
} from "../api";
import { GlobalReducerActions } from "./";
import { MapUtilsV2 } from "../commons/map";
import { AppUtils } from "../utils";

export const ZONE_REDUCER_TYPES = {
  FETCH_ALL_ZONES_SUCCESS: "FETCH_ALL_ZONES_SUCCESS",
  FETCH_ALL_ZONE_CATEGORIES_SUCCESS: "FETCH_ALL_ZONE_CATEGORIES_SUCCESS",
  SET_MAP_VIEW: "SET_MAP_VIEW",
  // ** Selected Zone Name  Types **
  SET_SELECTED_ZONE: "SET_SELECTED_ZONE",
  SET_SELECTED_ZONE_NAME: "SET_SELECTED_ZONE_NAME",
  SET_SELECTED_ZONE_HEAD_COUNT: "SET_SELECTED_ZONE_HEAD_COUNT",
  SET_SELECTED_ZONE_ENTRY_MESSAGE: "SET_SELECTED_ZONE_ENTRY_MESSAGE",
  SET_SELECTED_ZONE_EXIT_MESSAGE: "SET_SELECTED_ZONE_EXIT_MESSAGE",
  SET_SELECTED_ZONE_CATEGORIES: "SET_SELECTED_ZONE_CATEGORIES",
  SET_SELECTED_ZONE_COLOUR: "SET_SELECTED_ZONE_COLOUR",
  SET_SELECTED_ZONE_IS_TEMPORARY: "SET_SELECTED_ZONE_IS_TEMPORARY",
  SET_SELECTED_ZONE_SAVE_AS_TEMPLATE: "SET_SELECTED_ZONE_SAVE_AS_TEMPLATE",
  SET_SELECTED_MAP_GRAPHIC: "SET_SELECTED_MAP_GRAPHIC",
  SET_SELECTED_ZONE_BY_GRAPHIC_ID: "SET_SELECTED_ZONE_BY_GRAPHIC_ID",
  SET_SELECTED_ZONE_END_TIME: "SET_SELECTED_ZONE_END_TIME",
  SET_SELECTED_ZONE_START_TIME: "SET_SELECTED_ZONE_START_TIME",
  SET_SELECTED_ZONE_REACTIVATE_START_END_TIME: "SET_SELECTED_ZONE_REACTIVATE_START_END_TIME",
  // selected Category
  SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",
  SET_SELECTED_CATEGORY_NAME: "SET_SELECTED_CATEGORY_NAME",
  SET_SELECTED_CATEGORY_START_TIME: "SET_SELECTED_CATEGORY_START_TIME",
  SET_SELECTED_CATEGORY_END_TIME: "SET_SELECTED_CATEGORY_END_TIME",
  // ** Dialogs and Forms **
  SET_CREATE_ZONE_FORM_DISPLAY: "SET_CREATE_ZONE_FORM_DISPLAY",
  SET_CREATE_NEW_CATEGORY_DIALOG_DISPLAY: "SET_CREATE_NEW_CATEGORY_DIALOG_DISPLAY",
  SET_ADD_ZONE_DIALOG_DISPLAY: "SET_ADD_ZONE_DIALOG_DISPLAY",
  SET_REMOVE_ZONE_DIALOG: "SET_REMOVE_ZONE_DIALOG",
  SET_REMOVE_ZONE_CATEGORY_DIALOG: "SET_REMOVE_ZONE_CATEGORY_DIALOG",
  SET_SELECT_ZONE_FROM_TPL_DISPLAY: "SET_SELECT_ZONE_FROM_TPL_DISPLAY",
  SET_SHOW_ZONES_TPL_TABLE: "SET_SHOW_ZONES_TPL_TABLE",
  SET_ZONE_REACTIVATE_DIALOG_DISPLAY: "SET_ZONE_REACTIVATE_DIALOG_DISPLAY",
  SET_EDIT_ZONE_FROM_TPL_FORM: "SET_EDIT_ZONE_FROM_TPL_FORM",
  SET_EDIT_ZONE_DIALOG_DISPLAY: "SET_EDIT_ZONE_DIALOG",
  // Filter Panel + Filter Map Users
  SET_SHOW_FILTER_PANEL: "SET_SHOW_FILTER_PANEL",
  SET_FILTER_PANEL_ZONE_TYPE: "SET_FILTER_PANEL_ZONE_TYPE",
  SET_FILTER_PANEL_ZONE_CATEGORIES: "SET_FILTER_PANEL_ZONE_CATEGORIES",
  REMOVE_FILTER_PANEL_ZONE_CATEGORIES: "REMOVE_FILTER_PANEL_ZONE_CATEGORIES",
  CLEAR_FILTER_PANEL: "CLEAR_FILTER_PANEL",
  // Search
  SET_ZONES_SEARCH_VALUE: "SET_ZONES_SEARCH_VALUE",
  // MAP
  SET_MAP_EDIT_SHAPE_IN_PROGRESS: "SET_MAP_EDIT_SHAPE_IN_PROGRESS",
  // Filters
  SET_ZONES_VIEW_PAGE_FILTER: "SET_ZONES_VIEW_PAGE_FILTER",
  // Soft ALerts
  SET_DASHBOARD_MESSAGE_SOFT_ALERT: "SET_DASHBOARD_MESSAGE_SOFT_ALERT",
  SET_SITE_DEVICES_SUMMARY: "SET_SITE_DEVICES_SUMMARY",
  // Edit Zone Form State
  SET_EDIT_FORM: "SET_EDIT_FORM",
};

function customFilterZones(data, zonesViewPageFilter, filterPanel, searchValue) {
  // CORE ZONES FILTER LOGIC
  const activeZones = data.filter((zone) => zone.isActive);
  const inactiveZones = data.filter((zone) => !zone.isActive);
  const saveAsTemplateZones = data.filter((zone) => zone.saveAsTemplate);

  var arr = [];

  if (zonesViewPageFilter === "active") {
    arr = activeZones;
  } else if (zonesViewPageFilter === "inActive") {
    arr = activeZones;
  } else if (zonesViewPageFilter === "saveAsTemplate") {
    arr = saveAsTemplateZones;
  }

  let zonesToRender = [...arr];

  if (filterPanel.selectedZoneCategories.length) {
    zonesToRender = zonesToRender.filter((zoneItem) => {
      let isFilterOut = false;

      if (zoneItem.alerts.length) {
        isFilterOut = true;
      } else {
        zoneItem.categories.forEach((category) => {
          if (filterPanel.selectedZoneCategories.includes(category.id)) {
            isFilterOut = true;
          }
        });
      }

      return isFilterOut;
    });
  }

  if (filterPanel.selectedZoneTypes.length) {
    filterPanel.selectedZoneTypes.forEach((filterVal) => {
      zonesToRender = zonesToRender.filter((zoneItem) => filterVal.comparatorFn(zoneItem));
    });
  }

  zonesToRender = zonesToRender.filter(
    (zoneItem) => zoneItem.name && zoneItem.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return {
    allZones: [...data],
    activeZones,
    inactiveZones,
    saveAsTemplateZones,
    zonesToRender: zonesToRender,
  };
}

export const ZONES_INITIAL_STATE = {
  allZonesData: {
    allZones: [],
    activeZones: [],
    inactiveZones: [],
    saveAsTemplateZones: [],
  },
  zones: [],
  search: "",
  zonesViewPageFilter: "active",
  zoneCategories: {
    all: [],
    active: [],
    activeAndAppliedToZone: [],
  },
  mapView: "LARGE_MAP",
  filterPanel: {
    selectedZoneCategories: [],
    selectedZoneTypes: [],
  },
  selectedCategory: {},
  showFilterPanel: false,
  selectedZone: null,
  selectedMapGraphic: null,
  dialogs: {
    showCreateZoneForm: false,
    showAddZoneDialog: false,
    showRemoveZoneDialog: false,
    showRemoveZoneCategoryDialog: { id: null, show: false },
    showCreateNewCategoryDialog: false,
    showSelectZoneFromTpl: false,
    showZoneReactivateDialog: false,
    showEditZoneDialog: false,
  },
  showZonesTplTable: false,
  forms: {
    showEditZoneFromTplForm: {
      show: false,
    },
  },
  mapEditShapeInProgress: false,
  dashboardMessageSoftAlert: null,
  siteDevicesSummary: {},
  editingForm: false,
};

export const zonesReducer = (_state, action) => {
  const state = _state || ZONES_INITIAL_STATE;
  const { type, payload } = action;
  switch (type) {
    case ZONE_REDUCER_TYPES.FETCH_ALL_ZONES_SUCCESS:
      const {
        allZones,
        activeZones,
        inactiveZones,
        saveAsTemplateZones,
        zonesToRender,
      } = customFilterZones(payload, state.zonesViewPageFilter, state.filterPanel, state.search);

      return {
        ...state,
        allZonesData: {
          allZones: allZones,
          activeZones: activeZones,
          inactiveZones: inactiveZones,
          saveAsTemplateZones: saveAsTemplateZones,
        },
        zones: zonesToRender,
      };

    case ZONE_REDUCER_TYPES.SET_MAP_VIEW:
      return {
        ...state,
        mapView: action.payload,
      };

    case ZONE_REDUCER_TYPES.FETCH_ALL_ZONE_CATEGORIES_SUCCESS:
      const activeCategories = payload.filter(AppUtils.isZoneCategoryActive);
      return {
        ...state,
        zoneCategories: {
          all: payload,
          active: activeCategories,
          activeAndAppliedToZone: activeCategories.filter(
            (cat) => cat.name !== "Common Area" && cat.activeZones > 0
          ),
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE:
      return {
        ...state,
        selectedZone: action.payload,
      };

    case ZONE_REDUCER_TYPES.SET_FILTER_PANEL_ZONE_CATEGORIES:
      var fData = customFilterZones(
        state.allZonesData.allZones,
        state.zonesViewPageFilter,
        action.payload,
        state.search
      );
      return {
        ...state,
        zones: fData.zonesToRender,
        filterPanel: {
          ...state.filterPanel,
          ...action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_FILTER_PANEL_ZONE_TYPE:
      const fdata = customFilterZones(
        state.allZonesData.allZones,
        state.zonesViewPageFilter,
        action.payload,
        state.search
      );
      return {
        ...state,
        zones: fdata.zonesToRender,
        filterPanel: {
          ...state.filterPanel,
          ...action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_ZONES_SEARCH_VALUE:
      const searchedData = customFilterZones(
        state.allZonesData.allZones,
        state.zonesViewPageFilter,
        state.filterPanel,
        action.payload
      );
      return {
        ...state,
        zones: searchedData.zonesToRender,
        search: action.payload,
      };

    case ZONE_REDUCER_TYPES.SET_SHOW_FILTER_PANEL:
      return {
        ...state,
        showFilterPanel: action.payload,
      };

    case ZONE_REDUCER_TYPES.SET_SHOW_ZONES_TPL_TABLE:
      return {
        ...state,
        showZonesTplTable: action.payload,
      };

    case ZONE_REDUCER_TYPES.SET_EDIT_ZONE_FROM_TPL_FORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          showEditZoneFromTplForm: {
            show: action.payload.show,
          },
        },
      };

    // Categories Start *****

    case ZONE_REDUCER_TYPES.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_CATEGORY_NAME:
      return {
        ...state,
        selectedCategory: {
          ...state.selectedCategory,
          name: action.payload,
        },
      };
    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_END_TIME:
    case ZONE_REDUCER_TYPES.SET_SELECTED_CATEGORY_END_TIME:
      return {
        ...state,
        selectedZone: {
          ...state.selectedZone,
          endTime: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_START_TIME:
    case ZONE_REDUCER_TYPES.SET_SELECTED_CATEGORY_START_TIME:
      return {
        ...state,
        selectedZone: {
          ...state.selectedZone,
          startTime: action.payload,
        },
      };

    //** ------ Categories Ends

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_NAME:
      return {
        ...state,
        selectedZone: {
          ...state.selectedZone,
          name: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_IS_TEMPORARY:
      return {
        ...state,
        selectedZone: {
          ...state.selectedZone,
          isTemporary: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_SAVE_AS_TEMPLATE:
      return {
        ...state,
        selectedZone: {
          ...state.selectedZone,
          saveAsTemplate: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_COLOUR:
      return {
        ...state,
        selectedZone: {
          ...state.selectedZone,
          colour: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_HEAD_COUNT:
      return {
        ...state,
        selectedZone: {
          ...state.selectedZone,
          headcount: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_ENTRY_MESSAGE:
      return {
        ...state,
        selectedZone: {
          ...state.selectedZone,
          entryMessage: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_EXIT_MESSAGE:
      return {
        ...state,
        selectedZone: {
          ...state.selectedZone,
          exitMessage: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_CATEGORIES:
      return {
        ...state,
        selectedZone: {
          ...state.selectedZone,
          categories: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_REACTIVATE_START_END_TIME:
      return {
        ...state,
        selectedZone: {
          ...state.selectedZone,
          endTime: action.payload.endTime,
          startTime: action.payload.startTime,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_BY_GRAPHIC_ID:
      return {
        ...state,
        selectedZone: state.zones.filter((zone) => zone.id === action.payload)[0],
      };

    // Zone ends

    // Dialogs starts
    case ZONE_REDUCER_TYPES.SET_CREATE_NEW_CATEGORY_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showCreateNewCategoryDialog: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_CREATE_ZONE_FORM_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showCreateZoneForm: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_ZONE_REACTIVATE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showZoneReactivateDialog: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_ADD_ZONE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showAddZoneDialog: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_REMOVE_ZONE_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showRemoveZoneDialog: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_REMOVE_ZONE_CATEGORY_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showRemoveZoneCategoryDialog: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_EDIT_ZONE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showEditZoneDialog: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECT_ZONE_FROM_TPL_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showSelectZoneFromTpl: action.payload,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SELECTED_MAP_GRAPHIC:
      return {
        ...state,
        selectedMapGraphic: action.payload,
      };

    // MAP
    case ZONE_REDUCER_TYPES.SET_MAP_EDIT_SHAPE_IN_PROGRESS:
      return {
        ...state,
        mapEditShapeInProgress: action.payload,
      };

    // Zones Filter
    case ZONE_REDUCER_TYPES.SET_ZONES_VIEW_PAGE_FILTER:
      var filteredData = customFilterZones(
        state.allZonesData.allZones,
        payload,
        state.filterPanel,
        state.search
      );
      return {
        ...state,
        zonesViewPageFilter: payload,
        zones: filteredData.zonesToRender,
      };

    // Soft-alert
    case ZONE_REDUCER_TYPES.SET_DASHBOARD_MESSAGE_SOFT_ALERT:
      return {
        ...state,
        dashboardMessageSoftAlert: payload,
      };

    // Clears FilterPanel
    case ZONE_REDUCER_TYPES.CLEAR_FILTER_PANEL:
      return {
        ...state,
        filterPanel: {
          selectedZoneCategories: [],
          selectedZoneTypes: [],
        },
      };

    // remove filter panel
    case ZONE_REDUCER_TYPES.REMOVE_FILTER_PANEL_ZONE_CATEGORIES:
      const newFilterCategories = state.filterPanel.selectedZoneCategories.filter(
        (_id) => _id !== payload
      );
      return {
        ...state,
        filterPanel: {
          ...state.filterPanel,
          selectedZoneCategories: newFilterCategories,
        },
      };

    case ZONE_REDUCER_TYPES.SET_SITE_DEVICES_SUMMARY:
      return {
        ...state,
        siteDevicesSummary: payload,
      };

    case ZONE_REDUCER_TYPES.SET_EDIT_FORM:
      return {
        ...state,
        editingForm: payload,
      };

    default:
      return state;
  }
};

// ACTIONS
const getAllZones = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
    try {
      const siteID = getState().globalState.siteID;
      const response = await SiteLocationsAPI.getAllZonesBySitelocation(siteID);
      dispatch({ type: ZONE_REDUCER_TYPES.FETCH_ALL_ZONES_SUCCESS, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const createZone = (zoneData) => {
  return async (dispatch, getState) => {
    dispatch(GlobalReducerActions.showPageLoader());
    try {
      const selectedMapGraphic = getState().zonesScreen.selectedMapGraphic;
      const siteLocationId = getState().globalState.siteID;
      await ZonesAPI.createZone(zoneData, selectedMapGraphic, siteLocationId);
      dispatch({ type: ZONE_REDUCER_TYPES.SET_CREATE_ZONE_FORM_DISPLAY, payload: false });
      dispatch(ZonesReducerActions.getAllZones());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const createZoneFromTpl = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      const selectedZone = getState().zonesScreen.selectedZone;
      const selectedMapGraphic = getState().zonesScreen.selectedMapGraphic;
      delete selectedZone.id;
      if (!selectedZone.isTemporary && selectedZone.startTime && selectedZone.endTime) {
        delete selectedZone.startTime;
        delete selectedZone.endTime;
      }
      const siteLocationId = getState().globalState.siteID;
      await ZonesAPI.createZone(selectedZone, selectedMapGraphic, siteLocationId);
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_FROM_TPL_FORM,
        payload: { show: false },
      });
      dispatch({ type: ZONE_REDUCER_TYPES.SET_SHOW_ZONES_TPL_TABLE, payload: false });
      dispatch(ZonesReducerActions.getAllZones());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const deleteZone = (id, callback) => {
  return async (dispatch) => {
    dispatch(GlobalReducerActions.showPageLoader());
    try {
      await ZonesAPI.deleteZone(id);
      dispatch({ type: ZONE_REDUCER_TYPES.SET_REMOVE_ZONE_DIALOG, payload: false });
      dispatch(ZonesReducerActions.getAllZones());
      MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
      callback && callback();
      dispatch({ type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE, payload: null });
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateZone = (graphicData) => {
  return async (dispatch, getState) => {
    dispatch(GlobalReducerActions.showPageLoader());
    try {
      const { selectedZone } = getState().zonesScreen;
      const graphic = graphicData || getState().selectedMapGraphic;
      const siteID = getState().globalState.siteID;
      await ZonesAPI.updateZone(selectedZone, graphic, siteID);

      dispatch(ZonesReducerActions.getAllZones());
      dispatch({ type: ZONE_REDUCER_TYPES.SET_EDIT_FORM, payload: false });
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
        payload: null,
      });
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_SELECTED_MAP_GRAPHIC,
        payload: null,
      });
      return true;
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
      return false;
    }
  };
};

const reactivateZone = () => {
  return async (dispatch, getState) => {
    dispatch(GlobalReducerActions.showPageLoader());
    try {
      const { selectedZone } = getState().zonesScreen;
      selectedZone.startTime = new Date();
      await ZonesAPI.updateZone(selectedZone);

      dispatch({
        type: ZONE_REDUCER_TYPES.SET_ZONE_REACTIVATE_DIALOG_DISPLAY,
        payload: false,
      });
      dispatch({ type: ZONE_REDUCER_TYPES.SET_SHOW_ZONES_TPL_TABLE, payload: false });
      dispatch(ZonesReducerActions.getAllZones());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllZoneCategories = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
    try {
      const siteID = getState().globalState.siteID;
      const response = await SiteLocationsAPI.getAllZoneCategoriesBySiteLocation(siteID);
      dispatch({ type: ZONE_REDUCER_TYPES.FETCH_ALL_ZONE_CATEGORIES_SUCCESS, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const deleteZoneCategory = (zoneCategoryID) => {
  return async (dispatch) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      await ZoneCategoriesAPI.deleteZoneCategory(zoneCategoryID);
      await dispatch({
        type: ZONE_REDUCER_TYPES.REMOVE_FILTER_PANEL_ZONE_CATEGORIES,
        payload: zoneCategoryID,
      });
      await dispatch(ZonesReducerActions.getAllZoneCategories());
      await dispatch(ZonesReducerActions.getAllZones());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const createNewZoneCategory = (newCategoryData) => {
  return async (dispatch, getState) => {
    dispatch(GlobalReducerActions.showPageLoader());
    try {
      const siteLocationId = getState().globalState.siteID;
      await ZoneCategoriesAPI.createZoneCategory(newCategoryData, siteLocationId);
      dispatch(ZonesReducerActions.getAllZoneCategories());
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_CREATE_NEW_CATEGORY_DIALOG_DISPLAY,
        payload: false,
      });
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateZoneCategory = (selectedCategory) => {
  return async (dispatch) => {
    dispatch(GlobalReducerActions.showPageLoader());
    try {
      await ZoneCategoriesAPI.updateZoneCategory(selectedCategory);
      dispatch(ZonesReducerActions.getAllZoneCategories());
      dispatch(ZonesReducerActions.getAllZones());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const plotClear = (zoneId) => {
  return async (dispatch, getState) => {
    dispatch(GlobalReducerActions.showPageLoader());
    try {
      const siteId = getState().globalState.siteID;
      await AlertsAPI.plotClear(zoneId, siteId);
      dispatch(ZonesReducerActions.getAllZones());
      dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllDashboardMessages = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteId = getState().globalState.siteID;
      const response = await DashboardMessagesAPI.getAllDashboardMessegesBySiteId(siteId);
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_DASHBOARD_MESSAGE_SOFT_ALERT,
        payload: [response[0]],
      });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const init = (siteID) => {
  return async (dispatch) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      await dispatch(GlobalReducerActions.getSiteById(siteID));
      dispatch(ZonesReducerActions.getAllZones());
      dispatch(ZonesReducerActions.getAllZoneCategories());
      dispatch(GlobalReducerActions.getUserDevicesWithAppliedFilters());
      dispatch(GlobalReducerActions.getAllAlerts());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getOnlineAssignedDevices = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
    try {
      const siteID = getState().globalState.siteID;
      const response = await DevicesAPI.getSitelocationDevicesSummary(siteID);
      dispatch({ type: ZONE_REDUCER_TYPES.SET_SITE_DEVICES_SUMMARY, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

export const ZonesReducerActions = {
  getAllZones,
  createZone,
  deleteZone,
  updateZone,
  getAllZoneCategories,
  deleteZoneCategory,
  createNewZoneCategory,
  updateZoneCategory,
  createZoneFromTpl,
  reactivateZone,
  plotClear,
  getAllDashboardMessages,
  getOnlineAssignedDevices,
  init,
};
