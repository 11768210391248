import { MapUtilsV2 } from "../../../../../commons/map";

export const pointerMoveEventHandler = (
  mapView,
  sketchVM,
  sketch,
  state,
  currentGraphic,
  store,
  GRAPHICS_LAYERS_ID,
  viewAlerts = true
) => {
  mapView.on("pointer-move", (event) => {
    event.stopPropagation();

    if (state.shapeDrawInProgress) {
      sketch.update(currentGraphic);
      return;
    }

    mapView.hitTest(event).then((response) => {
      if (response?.results.length) {
        const graphics = response.results.filter(
          (result) => result?.graphic?.layer?.id === GRAPHICS_LAYERS_ID.USER_DEVICES
        );

        if (graphics.length) {
          mapView.popup.open({});
          if (mapView.popup.visible === false) {
            mapView.popup.visible = true;
          }

          MapUtilsV2.renderMapTooltipMarkup(graphics[0], store, mapView, viewAlerts);
        }
      }
    });
  });
};
