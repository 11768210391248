import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { ZoneManagement } from "./zone-management";
import { ZonesMapView } from "./zones-map-view";
import { CreateZoneForm, EditZoneFromTpl } from "./forms";
import { DocumentTitle } from "../../commons";
import { SoftAlertSnackbar } from "./components";
import { useZoneScreen } from "./hooks";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "85vh",
    paddingTop: "32px",
    paddingLeft: "24px",
    paddingRight: "24px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0",
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  zoneMapContainer: {
    position: "relative",
    marginRight: "12px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0",
      height: "40vh",
      marginRight: "0px",
    },
  },
  zoneManagementContainer: {
    paddingLeft: "12px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
    },
  },
}));

export const ZonesScreen = () => {
  const classes = useStyles();

  const {
    mapView,
    showEditZoneFromTplForm,
    showCreateZoneForm,
    selectZoneAndEdit,
    selectZoneAndEditFromTpl,
    selectZoneAndZoom,
    onCurrentZonesTableExpandBttnClick,
    handleCreateZoneBttnClick,
    onCreateZoneFromListTpl,
    onFilterShowHideCb,
    onLocateSiteCb,
    onActionBttnClick,
    showHidePlotPlan,
    showHideMapImageLayer,
    cancelEditTpl,
    confirmEditTpl,
    map,
  } = useZoneScreen();

  const ZoneManagementCMP = () => {
    if (showCreateZoneForm) {
      return (
        <CreateZoneForm
          onCancel={() => {
            map.cancelCreateGraphic();
            map.setFormOpen(false);
          }}
          onConfirm={() => map.setFormOpen(false)}
        />
      );
    } else if (showEditZoneFromTplForm.show) {
      return (
        <EditZoneFromTpl onCancel={() => cancelEditTpl()} onConfirm={() => confirmEditTpl()} />
      );
    } else {
      return (
        <>
          <DocumentTitle title="Sitewise | Zones" />
          <ZoneManagement
            mapView={mapView}
            isLargeMapView={mapView === "LARGE_MAP"}
            onCreateZoneBttnClick={handleCreateZoneBttnClick}
            onCurrentZonesTableExpandBttnClick={onCurrentZonesTableExpandBttnClick}
            onCreateZoneFromListTpl={onCreateZoneFromListTpl}
            selectZoneAndEdit={selectZoneAndEdit}
            selectZoneAndZoom={selectZoneAndZoom}
            selectZoneAndEditFromTpl={selectZoneAndEditFromTpl}
            onDeleteZoneCb={() => {
              map.unselectZone();
            }}
            map={map}
          />
        </>
      );
    }
  };

  const ZonesMapViewCMP = (
    <ZonesMapView
      mapView={mapView}
      onFilterShowHideCb={onFilterShowHideCb}
      onLocateSiteCb={onLocateSiteCb}
      onActionBttnClick={onActionBttnClick}
      showHidePlotPlan={showHidePlotPlan}
      showHideMapImageLayer={showHideMapImageLayer}
    />
  );

  if (mapView === "LARGE_MAP") {
    return (
      <Grid className={classes.wrapper}>
        <Grid md={9} xs={12} item className={classes.zoneMapContainer}>
          {ZonesMapViewCMP}
          <SoftAlertSnackbar />
        </Grid>
        <Grid md={3} xs={12} item className={classes.zoneManagementContainer}>
          {ZoneManagementCMP()}
        </Grid>
      </Grid>
    );
  } else if (mapView === "SMALL_MAP") {
    return (
      <Grid className={classes.wrapper}>
        <Grid md={5} xs={12} item className={classes.zoneMapContainer}>
          {ZonesMapViewCMP}
          <SoftAlertSnackbar />
        </Grid>
        <Grid md={7} xs={12} item className={classes.zoneManagementContainer}>
          {ZoneManagementCMP()}
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid className={classes.wrapper}>
        <Grid md={12} xs={12} item className={classes.zoneMapContainer}>
          {ZonesMapViewCMP}
          <SoftAlertSnackbar />
        </Grid>
      </Grid>
    );
  }
};
