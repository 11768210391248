import React, { useEffect, useState } from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Box,
  TextField,
  Collapse,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ICONS } from "../../../../../commons/icon-manager";
import { RowActionBttn, useCustomTableStyles, DeviceIdWithBatteryIcon } from "../../../../../commons";
import { ALERTS_REDUCER_TYPES, AlertsReducerActions } from "../../../../../reducers";
import { parseUtcToLocal } from "../../../../../utils";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: theme.palette.base.elevation_3,
    },
  },
  tableRowPointer: {
    cursor: "pointer",
  },
  selected: {},
  chipsWrapperRoot: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  actionBttnsWrapper: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  alertInfo: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "#D9D9D9",
    float: "left",
    width: "75%",
    paddingTop: "5px",
    marginBottom: "10px",
    '@media (max-width: 1500px)': {
      width: "100%",
    }
  },
  buttonContainer: {
    float: "right",
    width: "110px",
    marginBottom: "20px"
  }
}));

export const Row = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableStyles = useCustomTableStyles();
  const { rowData, headCells, isItemSelected } = props;
  const allDevices = useSelector((state) => state.devicesScreen?.devices);
  const [isSaveBttnDisabled, setIsSaveBttnDisabled] = useState(true);
  const [isTextFieldDisabled, setIsTextFieldDisabled] = useState(true);
  const [isEditBttnDisabled, setIsEditBttnDisabled] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState({});

  const [selectedAlert, setSelectedAlert] = useState({
    id: rowData?.id,
    note: rowData?.note?.note,
    isCleared: rowData?.isCleared,
  });

  const handleTableRowClicked = (event) => {
    event.stopPropagation();
  };

  function handleTableRowIconClick(event, data) {
    event.stopPropagation();

    if (!isItemSelected) {
      dispatch({
        type: ALERTS_REDUCER_TYPES.SET_SELECTED_ALERT_LOG,
        payload: data,
      });
    } else {
      dispatch({
        type: ALERTS_REDUCER_TYPES.SET_SELECTED_ALERT_LOG,
        payload: null,
      });
    }
  }

  function updateAlert() {
    dispatch(AlertsReducerActions.updateAlert(selectedAlert));
    setIsEditBttnDisabled(false);
    setIsSaveBttnDisabled(true);
    setIsTextFieldDisabled(true);
  }

  useEffect(() => {
    if (rowData.device) {
      setDeviceInfo(allDevices.filter((device) => device.id === rowData.device.id)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  return (
    <>
      <TableRow
        hover
        onClick={(event) => handleTableRowClicked(event)}
        role="checkbox"
        tabIndex={-1}
        selected={isItemSelected}
        className={classes.tableRow}
        classes={{
          selected: classes.selected,
        }}
      >
        {headCells.map((cell, index) => {
          return (
            <TableCell key={`${cell.id}-${index}`} className={tableStyles.cell}>
              <span>{cell.content(rowData)}</span>
            </TableCell>
          );
        })}

        <TableCell
          className={tableStyles.cell}
          key={`${rowData.id}-${rowData.name}-action-bttns`}
          style={{ textAlign: "right" }}
        >
          <IconButton
            color="primary"
            aria-label="expand row"
            size="small"
            onClick={(event) => {
              handleTableRowIconClick(event, rowData);
            }}
          >
            {isItemSelected ? <ICONS.KeyboardArrowUp /> : <ICONS.KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        className={classes.tableRow}
        classes={{
          selected: classes.selected,
        }}
        hover
        selected={isItemSelected}
        key={`${rowData.id}-collapsed-row`}
      >
        <TableCell
          key={`${rowData.id}-collapse`}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={4}
        >
          <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
            <Box display="flex" flexDirection="column" pt={1} pb={1}>
              <>
                <Box display="flex" pt={2}>
                  <TextField
                    fullWidth
                    disabled={isTextFieldDisabled}
                    helperText={`Note Last Updated: ${parseUtcToLocal(
                      rowData.lastModifiedTimestamp
                    )}`}
                    multiline
                    rows={3}
                    rowsMax={3}
                    variant="filled"
                    placeholder="Note"
                    value={selectedAlert.note}
                    onChange={(event) => {
                      setSelectedAlert({
                        ...selectedAlert,
                        note: event.target.value,
                      });
                    }}
                  />
                </Box>
              </>
            </Box>
            <div className={classes.alertInfo}>
              <div style={{ lineHeight: "120%" }}>Triggered by: {deviceInfo?.name} {rowData.createdBy} &nbsp;
              <div style={{ display: "inline-block" }}>
                  <DeviceIdWithBatteryIcon
                    manufacturerId={`#${deviceInfo?.manufacturerId}`}
                    level={deviceInfo?.status?.battery.internalLevel}
                    isOnline={deviceInfo?.status?.isOnline}
                  />
                </div>
              </div>
              <div style={{ lineHeight: "120%" }}>
                Cleared: {parseUtcToLocal(rowData.clearedTimestamp)} {rowData.clearedBy && ` by ${rowData.clearedBy}`}
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <RowActionBttn
                disabled={isEditBttnDisabled}
                title={"Edit Note"}
                onClick={() => {
                  setIsSaveBttnDisabled(false);
                  setIsTextFieldDisabled(false);
                  setIsEditBttnDisabled(true);
                }}
                color="primary"
                Icon={ICONS.Edit}
              />
              <Button
                disabled={isSaveBttnDisabled}
                className={tableStyles.rowActionBttn}
                variant="contained"
                color="primary"
                data-id={rowData.id}
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  updateAlert();
                }}
              >
                Save
              </Button>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
