import PersonPinCircle from "@material-ui/icons/PersonPinCircle";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeOff from "@material-ui/icons/VolumeOff";
import Build from "@material-ui/icons/Build";
import Terrain from "@material-ui/icons/Terrain";
import NotInterested from "@material-ui/icons/NotInterested";
import RoundedCornerOutlined from "@material-ui/icons/RoundedCornerOutlined";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/DeleteForever";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/Clear";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Add from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import Assign from "@material-ui/icons/Assignment";
import Done from "@material-ui/icons/Done";
import { UnAssign } from "./icons/un-assign";
import Create from "@material-ui/icons/Create";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CalendarToday from "@material-ui/icons/CalendarToday";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/icons/Menu";
import Publish from "@material-ui/icons/Publish";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import AddCircle from "@material-ui/icons/AddCircle";
import FilterList from "@material-ui/icons/FilterList";
import Person from "@material-ui/icons/Person";
import PersonOutline from "@material-ui/icons/PersonOutline";
import People from "@material-ui/icons/People";
import Inbox from "@material-ui/icons/MoveToInbox";
import Restore from "@material-ui/icons/Restore";
import StreetviewIcon from "@material-ui/icons/Streetview";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Visibility from "@material-ui/icons/Visibility";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckIcon from "@material-ui/icons/Check";
import MapIcon from '@material-ui/icons/Map';
import HomeIcon from '@material-ui/icons/Home';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import CloudIcon from '@material-ui/icons/Cloud';

export const FontAwesome = require("react-fontawesome");

export const ICONS = {
  // NO NAME CHANGE >>>
  VolumeUp: VolumeUp,
  Build: Build,
  Terrain: Terrain,
  NotInterested: NotInterested,
  // <<<< NO NAME CHANGE
  PersonPinCircle: PersonPinCircle,
  RoundedCornerOutlined: RoundedCornerOutlined,
  Edit: Edit,
  Delete: Delete,
  Save: Save,
  Cancel: Cancel,
  AddCircleOutline: AddCircleOutline,
  AddCircle,
  ChevronRight,
  ChevronLeft,
  Add: Add,
  Search: Search,
  Close: Close,
  Assign: Assign,
  UnAssign: UnAssign,
  Done: Done,
  Create: Create,
  FormatListBulleted: FormatListBulleted,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  CalendarToday,
  RemoveCircleOutline,
  AccountCircle,
  Menu,
  Publish,
  KeyboardArrowDown,
  KeyboardArrowUp,
  FilterList,
  Person,
  PersonOutline,
  People,
  Inbox,
  Restore,
  VolumeOff,
  StreetviewIcon,
  ArrowUpwardIcon,
  FilterNoneIcon,
  ArrowForwardIcon,
  OpenInNewIcon,
  Visibility,
  ZoomOutMapIcon,
  HelpOutlineIcon,
  CheckIcon,
  MapIcon,
  HomeIcon,
  PhoneAndroidIcon,
  PhoneIphoneIcon,
  PersonPinCircleIcon,
  CloudIcon
};

export const getIconByKey = (key) => {
  return ICONS[key];
};

export const IconManager = {
  getIconByKey,
  ICONS,
};
