import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  useTheme,
  useMediaQuery,
  makeStyles,
  Paper,
} from "@material-ui/core";
import {
  CommonFooter,
  EnhancedTableHead,
  useCustomTableHook,
  useCustomTableStyles,
  useDebounce,
} from "../../../../../commons";
import { Row } from "./row";
import { useSelector } from "react-redux";
import { parseUtcToLocal } from "../../../../../utils";

const CONSTANTS = {
  alertTitle: "alertTitle",
  createdTimestamp: "createdTimestamp",
  alertLabel: "alerts-log-table",
  alert: "Alert",
};
const useStyles = makeStyles(() => ({
  tableContariner: {
    maxHeight: "66vh",
  },
}));

const headCells = [
  {
    id: CONSTANTS.alertTitle,
    sortBy: CONSTANTS.alertTitle,
    label: CONSTANTS.alert,
    content: (row) => row.alertTitle,
  },
  {
    id: "zone",
    sortBy: "zone.name",
    label: "Zone",
    content: (row) => row.zone?.name,
  },
  {
    id: CONSTANTS.createdTimestamp,
    sortBy: CONSTANTS.createdTimestamp,
    label: "Date",
    content: (row) => parseUtcToLocal(row.createdTimestamp),
  },
];

export const AlertsLogTable = (props) => {
  const classes = useStyles();
  const tableClasses = useCustomTableStyles();
  const [tableSearchStr, setTableSearchStr] = useState("");
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    getComparator,
    stableSort,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({
    defaultSortBy: headCells[2].sortBy,
    rowsPerPage: isTablet ? 3 : 10,
    defaultSortOrder: "desc",
  });

  const { data } = props;

  const selectedAlertLog = useSelector((state) => state.alertsScreen.selected.alertLog);

  const [tableData, setTableData] = useState(data);

  const tableSearchDebounced = useDebounce((_nextVal) => {
    setPage(0);
    setTableSearchStr(_nextVal);
  }, 300);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    let arr = [];

    if (tableSearchStr) {
      arr = data.filter(
        (tData) =>
          tData.alertTitle && tData.alertTitle.toLowerCase().includes(tableSearchStr.toLowerCase())
      );
    } else {
      arr = data;
    }
    setTableData(arr);
  }, [tableSearchStr, setTableData, data]);

  if (tableData === null) {
    return null;
  }

  const checkIfRowSelected = (id) => selectedAlertLog && selectedAlertLog.id === id;

  return (
    <Paper>
      <TableContainer className={classes.tableContariner}>
        <Table
          aria-labelledby={CONSTANTS.alertLabel}
          aria-label={CONSTANTS.alertLabel}
          classes={{
            root: tableClasses.tableRoot,
          }}
          data-testid={CONSTANTS.alertLabel}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            toShow={true}
            tableWithRowActions={true}
            onChangeSearch={(event) => {
              tableSearchDebounced(event.target.value);
            }}
            searchLabel={CONSTANTS.alert}
          />
          <TableBody data-testid="alerts-log-table-body">
            {stableSort(tableData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rowData) => {
                const isItemSelected = checkIfRowSelected(rowData.id);
                return (
                  <Row
                    key={rowData.id}
                    rowData={rowData}
                    headCells={headCells}
                    isItemSelected={isItemSelected}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <CommonFooter
        componentType="div"
        rowsPerPage={rowsPerPage}
        page={page}
        tableClasses={tableClasses}
        tableData={tableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
