import { GlobalReducerActions } from ".";
import { SiteLocationsAPI, UserCategoryAPI, SuperUserAPI } from "../api";

const SITE_CONFIG_INITIAL_STATE = {
  sites: [],
  allSuperUser: [],
  users: [],
  pageIndex: 0,
  currentSite: null,
  fullMapView: true,
  currentSubSite: null,
  currentUserCategories: null,
  showDefaultSiteForm: false,
  newSiteFormVisible: false,
  showAddSubSiteBttn: false,
  mapState: {
    showMapInfoWidget: false,
    selectedSubSite: null,
    enableDoubleClick: false,
  },
  dialogs: {
    showCreateNewSuperUserDialog: false,
    showCreateNewSiteDialog: false,
    removeSubsiteDialog: {
      id: null,
      show: false,
    },
    showPrivacyUploadPdf: false,
    showDeviceDialog: false,
    showSuperAdminSettingsDialog: false,
    showDataPrivacyInfoDialog: false,
    deactivateSiteDialog: {
      showInfoDialog: false,
      site: null,
      showConfirmDialog: false,
    },
    reactivateSiteDialog: {
      showInfoDialog: false,
      site: null,
      showConfirmDialog: false,
    },
    createUserCategory: false,
    showRemoveUserCategoryDialog: false,
    showUpdateSuperAdminUser: false,
    showRemoveSuperAdminUser: false,
  },
  enabledPrivacySettings: false,
};

export const SITES_REDUCER_TYPES = {
  SET_SITES: "SET_SITES",
  SET_CURRENT_SITE: "SET_CURRENT_SITE",
  SET_SITE_SETTINGS: "SET_SITE_SETTINGS",
  SET_SITE_USER_CATEGORIES: "SET_SITE_USER_CATEGORIES",
  SET_SITE_SETTINGS_DEVICES: "SET_SITE_SETTINGS_DEVICES",
  SET_PAGE_INDEX: "SET_PAGE_INDEX",
  SET_FULL_MAP_VIEW: "SET_FULL_MAP_VIEW",
  SET_SHOW_MAP_INFO_WIDGET: "SET_SHOW_MAP_INFO_WIDGET",
  SET_ALL_SUPERUSER: "SET_ALL_SUPERUSER",
  // ** dialogs
  SET_CREATE_NEW_SITE_DIALOG_DISPLAY: "SET_CREATE_NEW_SITE_DIALOG_DISPLAY",
  SET_REMOVE_SUBSITE_DIALOG_DISPLAY: "SET_REMOVE_SUBSITE_DIALOG_DISPLAY",
  SET_REMOVE_SITE_DIALOG_DISPLAY: "SET_REMOVE_SITE_DIALOG_DISPLAY",
  SET_PRIVACY_UPLOAD_DIALOG_DISPLAY: "SET_PRIVACY_UPLOAD_DIALOG_DISPLAY",
  SET_DEVICE_DIALOG_DISPLAY: "SET_DEVICE_DIALOG_DISPLAY",
  SET_SUPER_ADMIN_SETTINGS_DISPLAY: "SET_SUPER_ADMIN_SETTINGS_DISPLAY",
  SET_DATA_PRIVACY_INFO_DIALOG_DISPLAY: "SET_DATA_PRIVACY_INFO_DIALOG_DISPLAY",
  SET_DEACTIVATE_SITE_DIALOG: "SET_DEACTIVATE_SITE_DIALOG",
  SET_REACTIVATE_SITE_DIALOG: "SET_REACTIVATE_SITE_DIALOG",
  SET_CREATE_NEW_SUPER_ADMIN_USER_DIALOG_DISPLAY: "SET_CREATE_NEW_SUPER_ADMIN_USER_DIALOG_DISPLAY",
  SET_CREATE_NEW_USER_CATEGORY_DIALOG: "SET_CREATE_NEW_USER_CATEGORY_DIALOG",
  SET_UPDATE_SUPER_USER_DIALOG_DISPLAY: "SET_UPDATE_SUPER_USER_DIALOG_DISPLAY",
  SET_REMOVE_SUPER_USER_DIALOG_DISPLAY: "SET_REMOVE_SUPER_USER_DIALOG_DISPLAY",
  SET_REMOVE_USER_CATEGORY_DIALOG: "SET_REMOVE_USER_CATEGORY_DIALOG",
  // site config actions
  SET_DEFAULT_SUBSITE: "SET_DEFAULT_SUBSITE",
  SET_SUBSITE: "SET_SUBSITE",
  SET_CURRENT_SUBSITE: "SET_CURRENT_SUBSITE",
  RESET_CURRENT_SUBSITE: "RESET_CURRENT_SUBSITE",
  SET_MAP_SELECTED_SUBSITE: "SET_MAP_SELECTED_SUBSITE",
  SET_DEVICE_CONFIG: "SET_DEVICE_CONFIG",
  SET_ENABLED_PLOT_CLEAR_REMINDER: "SET_ENABLED_PLOT_CLEAR_REMINDER",
  // forms
  NEW_SITE_FORM_VISIBLE: "NEW_SITE_FORM_VISIBLE",
  SET_SHOW_ADD_NEW_SITE_BTTN: "SET_SHOW_ADD_NEW_SITE_BTTN",
  // map
  SET_MAP_ENABLE_DOUBLE_CLICK: "SET_MAP_ENABLE_DOUBLE_CLICK",
};

export const sitesReducer = (state = SITE_CONFIG_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SITES_REDUCER_TYPES.SET_SITES:
      return {
        ...state,
        sites: payload,
      };

    case SITES_REDUCER_TYPES.SET_ALL_SUPERUSER:
      return {
        ...state,
        allSuperUser: payload,
      };

    case SITES_REDUCER_TYPES.SET_CURRENT_SITE:
      return {
        ...state,
        currentSite: payload,
        showAddSubSiteBttn: payload?.subSites?.length > 0 ? true : false,
        showDefaultSiteForm: payload && payload.subSites?.length === 0 ? true : false,
        mapState: {
          ...state.mapState,
          selectedSubSite: payload?.subSites?.length === 0 ? null : state.mapState?.selectedSubSite,
          enableDoubleClick: payload?.subSites?.length === 0 ? true : false,
        },
      };

    case SITES_REDUCER_TYPES.SET_PAGE_INDEX:
      return {
        ...state,
        pageIndex: payload,
      };

    case SITES_REDUCER_TYPES.SET_CREATE_NEW_SITE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showCreateNewSiteDialog: payload,
        },
      };

    case SITES_REDUCER_TYPES.SET_CREATE_NEW_SUPER_ADMIN_USER_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showCreateNewSuperUserDialog: payload,
        },
      };

    case SITES_REDUCER_TYPES.SET_SHOW_MAP_INFO_WIDGET:
      return {
        ...state,
        mapState: {
          ...state.mapState,
          showMapInfoWidget: payload,
        },
      };

    case SITES_REDUCER_TYPES.SET_MAP_SELECTED_SUBSITE:
      return {
        ...state,
        mapState: {
          ...state.mapState,
          selectedSubSite: payload,
        },
      };

    case SITES_REDUCER_TYPES.NEW_SITE_FORM_VISIBLE:
      return {
        ...state,
        newSiteFormVisible: payload,
      };

    case SITES_REDUCER_TYPES.SET_REMOVE_SUBSITE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          removeSubsiteDialog: payload,
        },
      };

    case SITES_REDUCER_TYPES.SET_DEFAULT_SUBSITE:
      state.currentSite.subSites[0] = payload;

      return {
        ...state,
        currentSite: {
          ...state.currentSite,
        },
      };

    case SITES_REDUCER_TYPES.SET_SUBSITE:
      state.currentSite.subSites[payload.id] = payload;
      return {
        ...state,
        currentSite: {
          ...state.currentSite,
        },
      };

    case SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE:
      return {
        ...state,
        currentSubSite: {
          ...state.currentSubSite,
          ...payload,
        },
      };

    case SITES_REDUCER_TYPES.RESET_CURRENT_SUBSITE:
      return {
        ...state,
        currentSubSite: payload,
        showAddSubSiteBttn: payload?.subSites?.length > 0 ? true : false,
      };

    case SITES_REDUCER_TYPES.SET_FULL_MAP_VIEW:
      return {
        ...state,
        fullMapView: payload,
      };

    case SITES_REDUCER_TYPES.SET_SITE_USER_CATEGORIES:
      return {
        ...state,
        currentUserCategories: payload,
      };

    case SITES_REDUCER_TYPES.SET_SITE_SETTINGS_DEVICES:
      return {
        ...state,
        currentSite: {
          ...state.currentSite,
          deviceList: payload,
        },
      };

    case SITES_REDUCER_TYPES.SET_PRIVACY_UPLOAD_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showPrivacyUploadPdf: payload,
        },
      };

    case SITES_REDUCER_TYPES.SET_SUPER_ADMIN_SETTINGS_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showSuperAdminSettingsDialog: payload,
        },
      };

    case SITES_REDUCER_TYPES.SET_DATA_PRIVACY_INFO_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showDataPrivacyInfoDialog: payload,
        },
      };

    case SITES_REDUCER_TYPES.SET_DEVICE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showDeviceDialog: payload,
        },
      };

    case SITES_REDUCER_TYPES.SET_DEACTIVATE_SITE_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          deactivateSiteDialog: {
            ...state.dialogs.deactivateSiteDialog,
            ...payload,
          },
        },
      };

    case SITES_REDUCER_TYPES.SET_REACTIVATE_SITE_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          reactivateSiteDialog: {
            ...state.dialogs.reactivateSiteDialog,
            ...payload,
          },
        },
      };

    case SITES_REDUCER_TYPES.SET_CREATE_NEW_USER_CATEGORY_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          createUserCategory: payload,
        },
      };

    case SITES_REDUCER_TYPES.SET_SHOW_ADD_NEW_SITE_BTTN:
      return {
        ...state,
        showAddSubSiteBttn: payload,
      };

    case SITES_REDUCER_TYPES.SET_MAP_ENABLE_DOUBLE_CLICK:
      return {
        ...state,
        mapState: {
          ...state.mapState,
          enableDoubleClick: payload,
        },
      };
    case SITES_REDUCER_TYPES.SET_DEVICE_CONFIG:
      return {
        ...state,
        currentSite: {
          ...state.currentSite,
          deviceConfiguration: {
            ...payload,
          },
        },
      };

    case SITES_REDUCER_TYPES.SET_REMOVE_USER_CATEGORY_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showRemoveUserCategoryDialog: payload,
        },
      };
    case SITES_REDUCER_TYPES.SET_UPDATE_SUPER_USER_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showUpdateSuperAdminUser: payload,
        },
      };
    case SITES_REDUCER_TYPES.SET_REMOVE_SUPER_USER_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showRemoveSuperAdminUser: payload,
        },
      };

    default:
      return state;
  }
};

const createNewSiteByName = (name) => {
  return async (dispatch) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      await SiteLocationsAPI.createSiteByName(name);
      dispatch(SitesReducerActions.getAllSites(false));
      dispatch({
        type: SITES_REDUCER_TYPES.SET_CREATE_NEW_SITE_DIALOG_DISPLAY,
        payload: false,
      });
      dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllSites = (showPageLoader = true) => {
  return async (dispatch) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const res = await SiteLocationsAPI.getAllSites();
      dispatch({ type: SITES_REDUCER_TYPES.SET_SITES, payload: res });
      dispatch(SitesReducerActions.getAllSuperUser());
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getSiteBySiteId = (siteId, showPageLoader = true) => {
  return async (dispatch) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteResponse = await SiteLocationsAPI.getSiteById(siteId);

      dispatch({ type: SITES_REDUCER_TYPES.SET_CURRENT_SITE, payload: siteResponse });
      dispatch(SitesReducerActions.getSiteUserCategories(siteId));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getSiteUserCategories = (siteId, showPageLoader = true) => {
  return async (dispatch) => {
    const userCategoryResponse = await UserCategoryAPI.getSiteUserCategories(siteId);
    dispatch({
      type: SITES_REDUCER_TYPES.SET_SITE_USER_CATEGORIES,
      payload: userCategoryResponse,
    });
  };
};

const updateSiteConfig = (_subSite, showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const currentSite = getState().sitesScreen.currentSite;
      if (_subSite) {
        currentSite.subSites[_subSite.id] = _subSite;
      }
      await SiteLocationsAPI.updateSiteById(currentSite);
      await dispatch(SitesReducerActions.getSiteBySiteId(currentSite.id, false));

      dispatch({ type: SITES_REDUCER_TYPES.RESET_CURRENT_SUBSITE, payload: null });
      dispatch({
        type: SITES_REDUCER_TYPES.SET_SHOW_MAP_INFO_WIDGET,
        payload: false,
      });

      dispatch({
        type: SITES_REDUCER_TYPES.SET_MAP_ENABLE_DOUBLE_CLICK,
        payload: false,
      });
      dispatch({
        type: SITES_REDUCER_TYPES.SET_SHOW_ADD_NEW_SITE_BTTN,
        payload: true,
      });

      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateSiteConfigAndExit = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const site = getState().sitesScreen.currentSite;
      await SiteLocationsAPI.updateSiteById(site);
      dispatch({ type: SITES_REDUCER_TYPES.SET_CURRENT_SITE, payload: null });

      dispatch({ type: SITES_REDUCER_TYPES.RESET_CURRENT_SUBSITE, payload: null });
      dispatch({
        type: SITES_REDUCER_TYPES.SET_SHOW_MAP_INFO_WIDGET,
        payload: false,
      });

      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const saveNewSubsite = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const currentSite = getState().sitesScreen.currentSite;
      const currentSubSite = getState().sitesScreen.currentSubSite;
      currentSite.subSites.push(currentSubSite);
      await SiteLocationsAPI.updateSiteById(currentSite);
      dispatch(SitesReducerActions.getSiteBySiteId(currentSite.id, false));
      dispatch({
        type: SITES_REDUCER_TYPES.RESET_CURRENT_SUBSITE,
        payload: null,
      });
      dispatch({
        type: SITES_REDUCER_TYPES.SET_SHOW_MAP_INFO_WIDGET,
        payload: false,
      });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const deactivateSite = (isDeactivate, showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      let site = null;
      if (isDeactivate) {
        site = getState().sitesScreen.dialogs.deactivateSiteDialog.site;
        site.active = false;
      } else {
        site = getState().sitesScreen.dialogs.reactivateSiteDialog.site;
        site.active = true;
      }

      await SiteLocationsAPI.updateSiteById(site);
      await dispatch(SitesReducerActions.getAllSites());

      if (isDeactivate) {
        dispatch({
          type: SITES_REDUCER_TYPES.SET_DEACTIVATE_SITE_DIALOG,
          payload: {
            showInfoDialog: false,
            site: null,
            showConfirmDialog: false,
          },
        });
      } else {
        dispatch({
          type: SITES_REDUCER_TYPES.SET_REACTIVATE_SITE_DIALOG,
          payload: {
            showInfoDialog: false,
            site: null,
            showConfirmDialog: false,
          },
        });
      }

      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const deleteSubSite = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const currentSite = getState().sitesScreen.currentSite;
      const removeSubsiteDialog = getState().sitesScreen.dialogs.removeSubsiteDialog;
      currentSite.subSites.splice(removeSubsiteDialog.id, 1);
      await SiteLocationsAPI.updateSiteById(currentSite);
      dispatch(SitesReducerActions.getSiteBySiteId(currentSite.id, false));
      dispatch({
        type: SITES_REDUCER_TYPES.SET_REMOVE_SUBSITE_DIALOG_DISPLAY,
        payload: {
          show: false,
          id: null,
        },
      });
      dispatch({
        type: SITES_REDUCER_TYPES.RESET_CURRENT_SUBSITE,
        payload: null,
      });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const addNewUserCategory = (newUserCategoryData, showPageLoader = true) => {
  return async (dispatch, getState) => {
    showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
    try {
      await UserCategoryAPI.createUserCategory(newUserCategoryData);
      dispatch(SitesReducerActions.getSiteUserCategories(newUserCategoryData.siteLocationId));
      dispatch({
        type: SITES_REDUCER_TYPES.SET_CREATE_NEW_USER_CATEGORY_DIALOG,
        payload: false,
      });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateUserCategory = (updatedUserCategoryData, showPageLoader = true) => {
  return async (dispatch, getState) => {
    showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
    try {
      await UserCategoryAPI.updateUserCategory(updatedUserCategoryData);
      dispatch(SitesReducerActions.getSiteUserCategories(updatedUserCategoryData.siteLocationId));
      dispatch({
        type: SITES_REDUCER_TYPES.SET_CREATE_NEW_USER_CATEGORY_DIALOG,
        payload: false,
      });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const deleteUserCategory = (userCategoryId, showPageLoader = true) => {
  return async (dispatch, getState) => {
    showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
    try {
      await UserCategoryAPI.deleteUserCategory(userCategoryId);
      const siteID = getState().sitesScreen.currentSite.id;
      dispatch(SitesReducerActions.getSiteUserCategories(siteID));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
    dispatch({ type: SITES_REDUCER_TYPES.SET_REMOVE_USER_CATEGORY_DIALOG, payload: false });
  };
};

const init = () => {
  return async (dispatch) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      await dispatch(SitesReducerActions.getAllSites(false));
      dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateSitePrivacyById = (data, showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      const payload = {
        id: getState().sitesScreen.currentSite.id,
        mapShowNameAlways: data.mapShowNameAlways || false,
        privacyPdfFile: data.privacyPdfFile,
        tableShowEmail: data.tableShowEmail || false,
        tableShowName: data.tableShowName || false,
        printConsent: data.printConsent || false,
        isAcknowledgementEnabled: data.isAcknowledgementEnabled || false,
      };
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      await SiteLocationsAPI.updateSitePrivacyById(payload);
      dispatch(SitesReducerActions.getSiteBySiteId(getState().sitesScreen.currentSite.id, false));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const patchSitePrivacyById = (data, showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      const payload = {
        mapShowNameAlways: data.mapShowNameAlways ?? false,
        tableShowEmail: data.tableShowEmail ?? false,
        tableShowName: data.tableShowName ?? false,
        printConsent: data.printConsent ?? false,
        isAcknowledgementEnabled: data.isAcknowledgementEnabled ?? false,
      };

      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      await SiteLocationsAPI.patchSitePrivacyById(getState().sitesScreen.currentSite.id, payload);
      dispatch(SitesReducerActions.getSiteBySiteId(getState().sitesScreen.currentSite.id, false));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const deleteSitePrivacyById = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      const id = getState().sitesScreen.currentSite.id;
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      await SiteLocationsAPI.deleteSitePrivacyById(id);
      dispatch(SitesReducerActions.getSiteBySiteId(id, false));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateDeviceConfigById = (data = {}, showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      const id = getState().sitesScreen.currentSite.id;
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      dispatch({ type: SITES_REDUCER_TYPES.SET_DEVICE_CONFIG, payload: data });
      const res = await SiteLocationsAPI.updateDeviceConfigById(data, id);
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
      return res.status;
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
      return error.statusCode;
    }
  };
};

const updateUserAttributesBySiteId = (data = {}, showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      const siteID = getState().sitesScreen.currentSite.id;
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      await SiteLocationsAPI.updateUserAttributesBySiteId(data, siteID);
      dispatch(SitesReducerActions.getSiteBySiteId(siteID, false));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateAlertConfigurationsBySiteId = (data = {}, showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      const siteID = getState().sitesScreen.currentSite.id;
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      await SiteLocationsAPI.updateAlertConfigurationsBySiteId(data, siteID);
      dispatch(SitesReducerActions.getSiteBySiteId(siteID, false));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateEvacuationMessageBySiteId = (data = "", showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      const siteID = getState().sitesScreen.currentSite.id;
      const body = {
        "id": siteID,
        "evacuationMessage": data
      }
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      await SiteLocationsAPI.updateEvacuationMessageBySiteId(body, siteID);
      dispatch(SitesReducerActions.getSiteBySiteId(siteID, false));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateEmergencyContactsBySiteId = (data = "", showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      const siteID = getState().sitesScreen.currentSite.id;
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      await SiteLocationsAPI.updateEmergencyContactsBySiteId(data, siteID);
      dispatch(SitesReducerActions.getSiteBySiteId(siteID, false));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const createSuperUser = (user) => {
  return async (dispatch, getState) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      const accessToken = getState().auth.accessToken;
      const siteLocationId = getState().globalState.siteID;
      await SuperUserAPI.createSuperUser(
        { ...user, siteLocationIds: [parseInt(siteLocationId)] },
        accessToken
      );
      dispatch(SitesReducerActions.getAllSuperUser());
      dispatch({
        type: SITES_REDUCER_TYPES.SET_CREATE_NEW_SUPER_ADMIN_USER_DIALOG_DISPLAY,
        payload: false,
      });
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
      dispatch({
        type: SITES_REDUCER_TYPES.SET_CREATE_NEW_SUPER_ADMIN_USER_DIALOG_DISPLAY,
        payload: false,
      });
    }
  };
};

const updateSuperUSer = (user) => {
  return async (dispatch) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      await SuperUserAPI.updateSuperUserById(user);
      dispatch(SitesReducerActions.getAllSuperUser());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
    dispatch({ type: SITES_REDUCER_TYPES.SET_UPDATE_SUPER_USER_DIALOG_DISPLAY, payload: false });
  };
};

const deleteSuperUSer = (userId) => {
  return async (dispatch) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      await SuperUserAPI.deleteSuperUser(userId);
      dispatch(SitesReducerActions.getAllSuperUser());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
    dispatch({ type: SITES_REDUCER_TYPES.SET_REMOVE_SUPER_USER_DIALOG_DISPLAY, payload: false });
  };
};

const getAllSuperUser = () => {
  return async (dispatch) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      const dataResult = await SuperUserAPI.getAllSuperUsers();
      dispatch({ type: SITES_REDUCER_TYPES.SET_ALL_SUPERUSER, payload: dataResult });
      dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

export const SitesReducerActions = {
  init,
  deactivateSite,
  createNewSiteByName,
  getAllSites,
  getSiteBySiteId,
  updateSiteConfig,
  saveNewSubsite,
  deleteSubSite,
  updateSiteConfigAndExit,
  updateSitePrivacyById,
  patchSitePrivacyById,
  deleteSitePrivacyById,
  updateDeviceConfigById,
  updateUserAttributesBySiteId,
  updateAlertConfigurationsBySiteId,
  createSuperUser,
  updateSuperUSer,
  getAllSuperUser,
  deleteSuperUSer,
  getSiteUserCategories,
  addNewUserCategory,
  updateUserCategory,
  deleteUserCategory,
  updateEvacuationMessageBySiteId,
  updateEmergencyContactsBySiteId
};
