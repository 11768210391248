import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  TableCell,
  TableRow,
  makeStyles,
  Box,
  TextField,
  IconButton,
  Tooltip,
  FormHelperText,
} from "@material-ui/core";

import {
  useCustomTableStyles,
  CategoryChip,
  ICONS,
  ColorPallete,
  UserPermissionContext,
} from "../../../../../commons";

import { ZONE_REDUCER_TYPES } from "../../../../../reducers";
import { ZoneCategoriesAutocomplete } from "../../zone-categories-autocomplete";
import { FONTS, parseUtcToLocal } from "../../../../../utils";
import { ALERT_COLOR_CODES } from "../../../../../config/alerts-color-codes";
import { CollapsedRowView } from "./collapsed-row-view";
import clsx from "clsx";

const SPACE_BETWEEN = "space-between";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: theme.palette.base.elevation_3,
    },
  },
  pointer: {
    cursor: "pointer",
  },
  MuiFormControlMarginNormal: {
    marginTop: 0,
  },
  tableCellAlert: {
    borderLeftWidth: "4px",
    borderLeftStyle: "solid",
  },
  selected: {},
  chipsWrapperRoot: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  actionBttnsWrapper: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  plotClearBttn: {
    color: "black",
    backgroundColor: "#FF453A",
    borderRadius: "4px",
  },
  alertLink: {
    color: "#FFFFFF",
    ...FONTS.Label2_Bold_12,
  },
  cardWrapper: {
    width: "100%",
  },
  usersInfo: {
    display: "flex",
    justifyContent: SPACE_BETWEEN,
    marginBottom: "30px",
  },
  actions: {
    display: "flex",
    justifyContent: SPACE_BETWEEN,
    alignItems: "center",
  },
  lastUpdated: {
    ...FONTS.B5_Reg_12,
  },
  alertsSpan: {
    ...FONTS.B5_Reg_12,
  },
  formControl: {
    width: "100%",
    marginTop: "12px",
  },
  helperText: {
    display: "flex",
    alignItems: "center",
    marginLeft: "47px",
  },
}));

const handleTableRowClicked = (rowData, isItemSelected, onTableRowSelectedCb, dispatch) => {
  if (!isItemSelected) {
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: rowData,
    });
    onTableRowSelectedCb && onTableRowSelectedCb(rowData);
  }
};

function handleTableRowIconClick(
  event,
  rowData,
  isItemSelected,
  setCategories,
  onTableExpandBttnCb,
  dispatch
) {
  event.stopPropagation();
  setCategories([...rowData.categories]);
  if (!isItemSelected) {
    onTableExpandBttnCb && onTableExpandBttnCb(rowData);
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: rowData,
    });
  } else {
    onTableExpandBttnCb && onTableExpandBttnCb(null);
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
      payload: false,
    });
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: null,
    });
  }
}

function renderChips(rowData) {
  const { categories } = rowData;

  if (categories) {
    return categories.map((category, index) => {
      return (
        <div style={{ marginTop: "5px", marginLeft: "5px" }} key={index}>
          <CategoryChip data={category} key={category.id} />
        </div>
      );
    });
  }

  return null;
}

function _getIsAlertPresent(rowData) {
  let isAlertPresent = false;
  let topAlert = null;

  if (rowData.alerts && rowData.alerts.length) {
    isAlertPresent = true;
    topAlert = rowData.alerts[0];
  }
  const borderLeftColorCode = topAlert
    ? ALERT_COLOR_CODES.AlertColor(topAlert.alertType).colorCode
    : "";

  const borderLeftColor = borderLeftColorCode
    ? `rgba(${borderLeftColorCode[0]}, ${borderLeftColorCode[1]}, ${borderLeftColorCode[2]})`
    : null;

  return { isAlertPresent, topAlert, borderLeftColor };
}

function _handleTableRowClick({
  rowData,
  selectedZone,
  isItemSelected,
  onTableRowSelectedCb,
  mapEditShapeInProgress,
  editForm,
  dispatch,
}) {
  if (!mapEditShapeInProgress && !editForm) {
    handleTableRowClicked(rowData, isItemSelected, onTableRowSelectedCb, dispatch);
  }
  if (editForm && selectedZone && selectedZone.id !== rowData.id) {
    dispatch({ type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_DIALOG_DISPLAY, payload: true });
  }
}

function _scrollToSelectedRow(isItemSelected, isLargeMapView, smallMapRef, fullMapRef) {
  if (isItemSelected) {
    if (isLargeMapView) {
      setTimeout(() => {
        fullMapRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
      }, 650);
    } else {
      setTimeout(() => {
        smallMapRef.current &&
          smallMapRef.current.parentElement.parentElement.parentElement.scroll({
            top: smallMapRef.current.offsetTop - 69,
            behavior: "smooth",
          });
      }, 650);
    }
  }
}

export const Row = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const tableStyles = useCustomTableStyles();
  const { Permissions } = useContext(UserPermissionContext);
  const mapEditShapeInProgress = useSelector((state) => state.zonesScreen.mapEditShapeInProgress);
  const editForm = useSelector((state) => state.zonesScreen.editingForm);
  const smallMapRef = useRef();
  const fullMapRef = useRef();

  const {
    rowData,
    onTableRowSelectedCb,
    onTableExpandBttnCb,
    isLargeMapView,
    isItemSelected,
    selectZoneAndEdit,
  } = props;

  useEffect(() => {
    _scrollToSelectedRow(isItemSelected, isLargeMapView, smallMapRef, fullMapRef);
  }, [isLargeMapView, isItemSelected]);

  const { isAlertPresent, topAlert, borderLeftColor } = _getIsAlertPresent(rowData);

  const zoneCategories = useSelector((state) => state.zonesScreen.zoneCategories?.active);
  const [categories, setCategories] = useState(rowData.categories);
  const [defaultCategory] = useState(
    zoneCategories.filter((cat) => cat.name.toLowerCase() === "common area")
  );

  const selectedZone = useSelector((state) => state.zonesScreen.selectedZone);
  const [saveBttnDisable, setSaveBttnDisable] = useState(true);

  return (
    <>
      <TableRow
        hover
        className={clsx(classes.tableRow, {
          [classes.pointer]: rowData.name !== "{Not in any zone}",
        })}
        classes={{
          selected: classes.selected,
        }}
        onClick={(event) => {
          event.stopPropagation();
          if (rowData.name === "{Not in any zone}") {
            return;
          }
          _handleTableRowClick({
            rowData,
            selectedZone,
            isItemSelected,
            onTableRowSelectedCb,
            mapEditShapeInProgress,
            editForm,
            dispatch,
          });
        }}
        tabIndex={-1}
        key={`${rowData.id}-firstRow`}
        selected={isItemSelected}
        ref={smallMapRef}
      >
        <TableCell
          className={
            isAlertPresent ? `${tableStyles.cell} ${classes.tableCellAlert}` : tableStyles.cell
          }
          align="left"
          style={{
            borderLeftColor: `${borderLeftColor}`,
          }}
          key={`${rowData.id}-color-pallete`}
        >
          <Box display="flex" alignItems="center">
            <Box pr={1.5}>
              <ColorPallete colour={rowData.displayColour} data={rowData} />
            </Box>
            {isItemSelected ? (
              <TextField
                id="filled-basic"
                disabled={!Permissions.Manage_Zones}
                variant="filled"
                value={selectedZone?.name}
                inputProps={{
                  style: { width: `${selectedZone.name.length * 10}px`, padding: "20px 10px 10px" },
                  maxLength: 30,
                }}
                onChange={(event) => {
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_NAME,
                    payload: event.target.value,
                  });
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
                    payload: true,
                  });
                  setSaveBttnDisable(false);
                }}
              />
            ) : (
              <span>{rowData.name}</span>
            )}
          </Box>
          {isItemSelected ? (
            <FormHelperText
              className={classes.helperText}
            >{`(${selectedZone.name.length}/30)`}</FormHelperText>
          ) : null}
        </TableCell>

        <TableCell
          key={`${rowData.id}-${rowData.name}-${rowData.headcount}-count`}
          className={tableStyles.cell}
        >
          {rowData.headcount}
        </TableCell>

        {rowData.alerts.length ? (
          <Tooltip
            classes={{
              tooltip: tableStyles.tooltip,
            }}
            title={
              <>
                <div className={tableStyles.tooltipContentWrapper}>
                  <span className={tableStyles.tooltipContent}>Click to View all Alerts</span>
                </div>
              </>
            }
            arrow
            placement="top"
          >
            <TableCell
              key={`${rowData.id}-${rowData.name}-${rowData.alerts}`}
              className={tableStyles.cell}
              onClick={() => {
                history.push("/alerts");
              }}
            >
              {rowData.alerts.length}
            </TableCell>
          </Tooltip>
        ) : (
          <TableCell className={tableStyles.cell} key={`${rowData.id}-${rowData.name}-headcount`}>
            0
          </TableCell>
        )}

        <TableCell key={`${rowData.id}-${rowData.name}-categories`} className={tableStyles.cell}>
          <div className={classes.chipsWrapperRoot}>
            {Permissions.Manage_Zones && isItemSelected ? (
              <ZoneCategoriesAutocomplete
                value={categories}
                options={zoneCategories}
                onChange={(_, newValues) => {
                  const newCategories = [
                    ...defaultCategory,
                    ...newValues.filter((option) => option.id !== defaultCategory[0].id),
                  ];
                  setCategories(newCategories);
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_CATEGORIES,
                    payload: newCategories,
                  });
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
                    payload: true,
                  });
                  setSaveBttnDisable(false);
                }}
              />
            ) : (
              renderChips(rowData)
            )}
          </div>
        </TableCell>

        <TableCell
          key={`${rowData.id}-${rowData.name}-${rowData.createdTimestamp}`}
          className={tableStyles.cell}
        >
          <span>
            {topAlert && topAlert.createdTimestamp
              ? parseUtcToLocal(topAlert.createdTimestamp)
              : parseUtcToLocal(rowData.createdTimestamp)}
          </span>
        </TableCell>

        <TableCell
          key={`${rowData.id}-${rowData.name}-action-bttns`}
          style={{ textAlign: "right" }}
          className={tableStyles.cell}
        >
          {!rowData.isFrontEndOnly && (
            <IconButton
              disabled={mapEditShapeInProgress}
              color="primary"
              aria-label="expand row"
              size="small"
              onClick={(event) => {
                if (editForm) {
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_DIALOG_DISPLAY,
                    payload: true,
                  });
                } else {
                  handleTableRowIconClick(
                    event,
                    rowData,
                    isItemSelected,
                    setCategories,
                    onTableExpandBttnCb,
                    dispatch
                  );
                }
              }}
            >
              {isItemSelected ? <ICONS.KeyboardArrowUp /> : <ICONS.KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      {CollapsedRowView({
        rowData,
        classes,
        fullMapRef,
        isItemSelected,
        selectedZone,
        dispatch,
        setSaveBttnDisable,
        selectZoneAndEdit,
        Permissions,
        mapEditShapeInProgress,
        onTableExpandBttnCb,
        tableStyles,
        saveBttnDisable,
        editForm,
      })}
    </>
  );
};
