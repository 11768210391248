import { AxiosClient } from "./axios-client";
import { MapUtils } from "../routes/zones/components/zone-map/map-utils";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";

async function getGraphicSpecificAttrs(graphic) {
  const { geometry } = graphic;
  return {
    polygonPoints: await MapUtils.getPolygonPoints(geometry.rings[0]),
  };
}

const createZone = async (zone, graphic, siteLocationId) => {
  try {
    const headers = await authService.getAuthorizeHeader();
    const graphicAttrs = await getGraphicSpecificAttrs(graphic);
    const postData = {
      ...zone,
      ...graphicAttrs,
      siteLocationId: siteLocationId,
    };

    postData.categoryIds = postData.categories.map((category) => category.id);
    delete postData.categories;

    return await AxiosClient({
      url: `/api/zone`,
      method: "POST",
      data: postData,
      headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateZone = async (zone, graphic, siteID) => {
  let graphicAttrs = {};
  if (graphic) {
    graphicAttrs = await getGraphicSpecificAttrs(graphic);
  }

  const postData = {
    ...zone,
    ...graphicAttrs,
    siteLocationId: siteID,
  };

  const { id } = postData;
  postData.categoryIds = zone.categories.map((category) => category.id);
  delete postData.categories;

  try {
    const headers = await authService.getAuthorizeHeader();
    return await AxiosClient({
      url: `/api/zone/${id}`,
      method: "PUT",
      data: postData,
      headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getZoneById = async (zoneId) => {
  try {
    const headers = await authService.getAuthorizeHeader();
    const res = await AxiosClient({
      url: `/api/zone/${zoneId}`,
      method: "GET",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const deleteZone = async (zoneId) => {
  try {
    const headers = await authService.getAuthorizeHeader();
    const res = await AxiosClient({
      url: `/api/zone/${zoneId}`,
      method: "DELETE",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

export const ZonesAPI = {
  createZone,
  updateZone,
  getZoneById,
  deleteZone,
};
