import React, { useState, useContext  } from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Button,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { YellowSwitch } from "../../../../commons/switch";
import { YellowRadio } from "../../../../commons/radio-button";
import { YellowCheckbox, DisabledYellowCheckbox } from "../../../../commons/checkbox";
import { UploadPdfDialog } from "../../components/dialogs";
import { FONTS } from "../../../../utils";
import { ICONS, InfoDialog as DataPrivacyInfoDialog, UserPermissionContext } from "../../../../commons";
import { SITES_REDUCER_TYPES, SitesReducerActions } from "../../../../reducers";
import { pdfConsentContent } from "./pdf-consent-content";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    paddingTop: "25px",
    paddingBottom: "25px",
    [`${theme.breakpoints.down("md")} and (orientation: landscape)`]: {
      paddingBottom: "5px",
      paddingTop: "5px",
    },
  },
  formGroup: {
    display: "flex",
  },
  formLabel1: {
    ...FONTS.Button1_Bold_14,
  },
  formLabel2: {
    marginBottom: "17px",
    ...FONTS.Label2_ShellBold_12,
  },
  uploadButton: {
    color: "#FBCE07",
    width: "50%",
    ...FONTS.Button1_Bold_16,
  },
  checkboxContainer: {
    marginRight: "60px",
  },
  icons: {
    cursor: "pointer",
    marginLeft: "5px",
  },
  iconContainer: {
    display: "flex",
    aliginItems: "center",
  },
  pdfContainer: {
    display: "flex",
    alignItems: "center",
    ...FONTS.Button1_Bold_14,
  },
  pdfText: {
    marginRight: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    margin: "10px",
    color: "#FBCE07",
    border: "1px solid",
    ...FONTS.Button1_Bold_16,
  },
  primaryButton: {
    margin: "10px",
    backgroundColor: "#FBCE07",
    ...FONTS.Button1_Bold_16,
  },
}));

function footerBttns({
  classes,
  navigateToSites,
  isSaveBttnDisabled,
  currentSite,
  dispatch,
  privacy,
  setActive,
  manageSites
}) {
  return (
    <div className={classes.buttonContainer}>
      <Button
        size="medium"
        variant="outlined"
        className={classes.button}
        onClick={() => {
          navigateToSites();
        }}
      >
        Back to Site Coordinates
      </Button>
      <Button
        size="medium"
        variant="outlined"
        className={classes.button}
        onClick={() => {
          navigateToSites();
        }}
      >
        Cancel
      </Button>
      <Button
        size="medium"
        disabled={isSaveBttnDisabled}
        variant="contained"
        className={classes.primaryButton}
        onClick={() => {
          savePrivacySettings({ currentSite, dispatch, privacy, setActive });
        }}
      >
        {manageSites ? "Save and Next" : "Next"}
      </Button>
    </div>
  );
}

function renderForm({ classes, privacy, enabledPrivacySettings, setPrivacy, manageSites }) {
  return (
    <div className={classes.formContainer}>
      <label className={classes.formLabel2}>PRIVACY AND PI DATA CONSENT ACCESS</label>
      <FormGroup>
        <FormControlLabel
          control={
            <DisabledYellowCheckbox
              checked={true}
              name="email"
              disabled={true}
              className={classes.formLabel1}
            />
          }
          label={
            <span className={classes.formLabel1}>
              Email (Users will get automated email with access to privacy consent form)
            </span>
          }
          className={classes.formLabel1}
        />
        <FormControlLabel
          control={
            <YellowCheckbox
              checked={privacy.printConsent}
              onChange={(e) => setPrivacy({ ...privacy, printConsent: e.target.checked })}
              name="printConsent"
              disabled={!enabledPrivacySettings || !manageSites}
            />
          }
          label={
            <span className={classes.formLabel1}>
              Print (Allows manual update of status on Sitewise)
            </span>
          }
        />
        <FormControlLabel
          control={
            <YellowCheckbox
              checked={privacy.isAcknowledgementEnabled}
              onChange={(e) =>
                setPrivacy({ ...privacy, isAcknowledgementEnabled: e.target.checked })
              }
              name="isAcknowledgementEnabled"
              disabled={!enabledPrivacySettings || !manageSites}
            />
          }
          label={
            <span className={classes.formLabel1}>
              Do not require user consent (Shows only acknowledge option for privacy consent form)
            </span>
          }
        />
      </FormGroup>
    </div>
  );
}

function renderFormElements({classes, privacy, enabledPrivacySettings, setPrivacy, manageSites, manageSites_1 }){
  return (
    <div className={classes.formGroup}>
    <FormGroup className={classes.checkboxContainer}>
      <label className={classes.formLabel2}>ON TABLE</label>
      <FormControlLabel
      disabled={!manageSites}
        control={
          <YellowCheckbox
            checked={privacy.tableShowName}
            onChange={(e) => {
              setPrivacy({ ...privacy, tableShowName: e.target.checked });
            }}
            name="tableShowName"
            disabled={!enabledPrivacySettings}
          />
        }
        label={<span className={classes.formLabel1}>Show Name</span>}
      />
      <FormControlLabel
        disabled={!manageSites}
        control={
          <YellowCheckbox
            checked={privacy.tableShowEmail}
            onChange={(e) => setPrivacy({ ...privacy, tableShowEmail: e.target.checked })}
            name="tableShowEmail"
            disabled={!enabledPrivacySettings}
          />
        }
        label={<span className={classes.formLabel1}>Show Email</span>}
        className={classes.formLabel1}
      />
    </FormGroup>
    <div>
      <label className={classes.formLabel2}>ON MAP</label>
      <RadioGroup
        row
        className={classes.formLabel1}
        value={
          privacy.mapShowNameAlways === null
            ? null
            : privacy.mapShowNameAlways
            ? "true"
            : "false"
        }
        onChange={(e) => {
          setPrivacy({ ...privacy, mapShowNameAlways: e.target.value === "true" });
        }}
      >
        <FormControlLabel
          value={"true"}
          control={<YellowRadio />}
          label={<span className={classes.formLabel1}>Always Show Name</span>}
          className={classes.formLabel1}
          disabled={manageSites_1}
        />
        <FormControlLabel
          value="false"
          control={<YellowRadio />}
          label={<span className={classes.formLabel1}>Only Show Name on Emergency</span>}
          className={classes.formLabel1}
          disabled={manageSites_1}
        />
      </RadioGroup>
    </div>
  </div>
  )
}


const savePrivacySettings = ({ currentSite, dispatch, privacy, setActive }) => {
  if (currentSite?.privacySettings?.privacyPdfUrl && !privacy.privacyPdfUrl) {
    dispatch(SitesReducerActions.deleteSitePrivacyById(true));
  } else if (privacy.privacyPdfFile instanceof File) {
    dispatch(
      SitesReducerActions.updateSitePrivacyById(
        { ...privacy, mapShowNameAlways: privacy.mapShowNameAlways },
        true
      )
    );
  } else {
    dispatch(
      SitesReducerActions.patchSitePrivacyById(
        { ...privacy, mapShowNameAlways: privacy.mapShowNameAlways },
        true
      )
    );
  }

  setActive(1);
};

const handleToggleSwitch = ({ e, dispatch, setEnabledPrivacySettings}) => {
  if (e.target.checked) {
    setEnabledPrivacySettings(true);
  } else {
    dispatch({
      type: SITES_REDUCER_TYPES.SET_DATA_PRIVACY_INFO_DIALOG_DISPLAY,
      payload: true,
    });
  }
};

const isBttnDisabled = ({ enabledPrivacySettings, privacy, currentSite }) => {
  if (enabledPrivacySettings) {
    if (privacy.privacyPdfUrl !== "") {
      return false;
    } else {
      return true;
    }
  } else {
    return !currentSite.privacySettings;
  }
};

function handleInfoDialogBttn({ dispatch }) {
  dispatch({
    type: SITES_REDUCER_TYPES.SET_DATA_PRIVACY_INFO_DIALOG_DISPLAY,
    payload: false,
  });
}

export const PersonalInfoForm = ({ currentSite, navigateToSites, setActive }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { Permissions } = useContext(UserPermissionContext);
  const manageSites = Permissions?.Manage_Sites;
  const [privacy, setPrivacy] = useState({
    tableShowName: currentSite?.privacySettings?.tableShowName ?? false,
    tableShowEmail: currentSite?.privacySettings?.tableShowEmail ?? false,
    printConsent: currentSite?.privacySettings?.printConsent ?? false,
    privacyPdfUrl: currentSite?.privacySettings?.privacyPdfUrl ?? "",
    emailConsent: currentSite?.privacySettings?.emailConsent ?? false,
    mapShowNameAlways: currentSite?.privacySettings?.mapShowNameAlways ?? null,
    isAcknowledgementEnabled: currentSite?.privacySettings?.isAcknowledgementEnabled ?? false,
  });

  const [enabledPrivacySettings, setEnabledPrivacySettings] = useState(
    !!currentSite?.privacySettings
  );

  const manageSites_1 = !enabledPrivacySettings || !manageSites;

  const uploadDialog = useSelector((state) => state.sitesScreen.dialogs.showPrivacyUploadPdf);

  const showDataPrivacyInfoDialog = useSelector(
    (state) => state.sitesScreen.dialogs.showDataPrivacyInfoDialog
  );

  const handlePdfUpload = (file) => {
    setPrivacy({ ...privacy, consent: file, privacyPdfFile: file, privacyPdfUrl: file.name });
    dispatch({ type: SITES_REDUCER_TYPES.SET_PRIVACY_UPLOAD_DIALOG_DISPLAY, payload: false });
  };

  const handlePrivacyDialog = (payload) => {
    dispatch({
      type: SITES_REDUCER_TYPES.SET_PRIVACY_UPLOAD_DIALOG_DISPLAY,
      payload,
    });
  };

  const isSaveBttnDisabled = isBttnDisabled({ enabledPrivacySettings, privacy, currentSite });

  return (
    <>
      <FormControl style={{ height: "48vh" }}>
        <FormControlLabel
          disabled={!manageSites}
          label={<span className={classes.formLabel1}>Enable Showing Personal Information</span>}
          className={classes.formContainer}
          control={
            <YellowSwitch
              checked={enabledPrivacySettings}
              onChange={(e) =>
                handleToggleSwitch({ e, dispatch, setEnabledPrivacySettings, setPrivacy, privacy })
              }
            />
          }
        />
        {renderFormElements({classes, privacy, enabledPrivacySettings, setPrivacy, manageSites, manageSites_1 })}

        {renderForm({ classes, privacy, enabledPrivacySettings, setPrivacy, manageSites })}

        <label className={classes.formLabel2}>EDIT DATA CONSENT FORM</label>

        {!privacy.consent ? (
          pdfConsentContent({
            privacyPdfUrl: privacy.privacyPdfUrl,
            enabledPrivacySettings,
            classes,
            handlePrivacyDialog,
            setPrivacy,
            privacy,
            manageSites
          })
        ) : (
          <div className={classes.pdfContainer}>
            <span className={classes.pdfText}>{privacy.consent.name}</span>
            {manageSites ? (
            <span className={classes.iconContainer}>
              <Tooltip title="Upload File">
                <ICONS.ArrowUpwardIcon
                  onClick={() => handlePrivacyDialog(true)}
                  className={classes.icons}
                />
              </Tooltip>
              <Tooltip title="Remove File">
                <ICONS.Delete
                  onClick={() =>
                    setPrivacy({
                      ...privacy,
                      consent: null,
                      privacyPdfFile: null,
                      privacyPdfUrl: "",
                    })
                  }
                  className={classes.icons}
                />
              </Tooltip>
            </span> ) : ""}
          </div>
        )}
      </FormControl>

      {footerBttns({
        classes,
        navigateToSites,
        isSaveBttnDisabled,
        currentSite,
        dispatch,
        privacy,
        setActive,
        manageSites
      })}

      {uploadDialog ? (
        <UploadPdfDialog
          show={uploadDialog}
          onCancel={() => handlePrivacyDialog(false)}
          onConfirm={handlePdfUpload}
        />
      ) : null}

      <DataPrivacyInfoDialog
        show={showDataPrivacyInfoDialog}
        infoText={`<div>
          <span>By disabling this option, user data will no longer be collected and 
          devices may be assigned without consent form acceptance but you will no longer be able to 
          see or manage user data.</span>
          <span>Settings will be saved as is untill you re-enable data collection.</span>
         </div>`}
        bttnOne={{ label: "Disable", Icon: ICONS.CheckIcon }}
        bttnTwo={{ label: "Cancel" }}
        onCancel={() => {
          handleInfoDialogBttn({ dispatch });
        }}
        onConfirmActionOne={() => {
          setEnabledPrivacySettings(false);
          dispatch({
            type: SITES_REDUCER_TYPES.SET_DATA_PRIVACY_INFO_DIALOG_DISPLAY,
            payload: false,
          });

          setPrivacy({
            ...privacy,
            printConsent: false,
            tableShowName: false,
            tableShowEmail: false,
            mapShowNameAlways: null,
            privacyPdfUrl: "",
            isAcknowledgementEnabled: false,
          });
        }}
        onConfirmActionTwo={() => {
          handleInfoDialogBttn({ dispatch });
        }}
      />
    </>
  );
};
