import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";

const API_URL = "/api/Alert";

const getAllActiveAlertsBySiteLocation = async (siteId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `/api/SiteLocation/${siteId}/Alert?c=0`,
      method: "GET",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getAllClearedAlertsBySiteLocation = async (siteId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `/api/SiteLocation/${siteId}/Alert?c=1&ct=100`,
      method: "GET",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getAlertById = async (id) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${id}`,
      method: "GET",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateAlertById = async (data) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${data.id}`,
      method: "PUT",
      data: data,
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const plotClear = async (zoneID, siteID) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/plotclear`,
      method: "POST",
      data: {
        zoneId: zoneID,
        siteLocationId: siteID,
      },
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

export const AlertsAPI = {
  getAllActiveAlertsBySiteLocation,
  getAlertById,
  updateAlertById,
  getAllClearedAlertsBySiteLocation,
  plotClear,
};
