import React, { useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  TableCell,
  TableRow,
  Button,
  makeStyles,
  Box,
  Collapse,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  useCustomTableStyles,
  CategoryChip,
  ICONS,
  ColorPallete,
  UserPermissionContext,
} from "../../../../../commons";
import { ZONE_REDUCER_TYPES, ZonesReducerActions } from "../../../../../reducers";
import { AlertChip } from "../..";
import { UsersCount } from "../../../../../commons/user-count";
import { FONTS, AppUtils, parseUtcToLocal } from "../../../../../utils";
import { ALERT_COLOR_CODES } from "../../../../../config/alerts-color-codes";
import clsx from "clsx";

const SPACE_BETWEEN = "space-between";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: theme.palette.base.elevation_3,
    },
  },
  pointer: {
    cursor: "pointer",
  },
  tableCellAlert: {
    borderLeftWidth: "4px",
    borderLeftStyle: "solid",
  },
  lastUpdated: {
    ...FONTS.B5_Reg_12,
  },
  selected: {},
  chipsWrapperRoot: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  plotClearBttn: {
    borderRadius: "4px",
    color: "black",
    backgroundColor: "#FF453A",
  },

  cardWrapper: {
    width: "100%",
  },
  usersInfo: {
    justifyContent: SPACE_BETWEEN,
    marginBottom: "30px",
    display: "flex",
  },
  actions: {
    justifyContent: SPACE_BETWEEN,
    alignItems: "center",
    display: "flex",
  },
  alertsSpan: {
    ...FONTS.B5_Reg_12,
  },
  alertLink: {
    ...FONTS.Label2_Bold_12,
    color: "#FFFFFF",
  },
}));

function renderRowActionCell({
  rowData,
  tableStyles,
  mapEditShapeInProgress,
  isItemSelected,
  dispatch,
  onTableExpandBttnCb,
}) {
  return (
    <TableCell
      key={`${rowData.id}-${rowData.name}-action-bttns`}
      style={{ textAlign: "right" }}
      className={tableStyles.cell}
    >
      {!rowData.isFrontEndOnly && (
        <IconButton
          disabled={mapEditShapeInProgress}
          color="primary"
          aria-label="expand row"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            _handleTableRowIconClick({
              rowData,
              isItemSelected,
              dispatch,
              onTableExpandBttnCb,
            });
          }}
        >
          {isItemSelected ? <ICONS.KeyboardArrowUp /> : <ICONS.KeyboardArrowDown />}
        </IconButton>
      )}
    </TableCell>
  );
}

function renderChips(rowData) {
  const { categories } = rowData;

  if (categories) {
    return categories.map((category, index) => {
      return (
        <div style={{ marginTop: "5px" }} key={index}>
          <CategoryChip data={category} key={category.id} />
        </div>
      );
    });
  }

  return null;
}

function checkToRender(sortyBy, orderBy) {
  return sortyBy === orderBy;
}

function renderAlertChip(alert) {
  return alert ? <AlertChip data={alert} key={alert.id} /> : null;
}

function renderNonAlertTableCell({ rowData, isItemSelected, selectedZone }) {
  return (
    <TableCell
      key={`${rowData.id}-collapse-without-alert`}
      style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: "45px" }}
      colSpan={4}
    >
      <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
        <Box display="flex" flexDirection="column" pt={1} pb={1}>
          <Box style={{ padding: "8px", paddingLeft: "0px" }}>{renderChips(rowData)}</Box>
          <Box style={{ padding: "8px", paddingLeft: "0px" }}>
            Entry Message: {selectedZone && selectedZone.entryMessage}
          </Box>
          <Box style={{ padding: "8px", paddingLeft: "0px" }}>
            Exit Message: {selectedZone && selectedZone.exitMessage}
          </Box>
          <Box style={{ padding: "8px", paddingLeft: "0px" }}>
            <div>
              <div>
                {selectedZone && (
                  <Tooltip title={selectedZone.createdBy}>
                    <span>
                      {`Zone Created by ${AppUtils.parseEmailToName(selectedZone.createdBy)} `}
                    </span>
                  </Tooltip>
                )}
                {selectedZone && parseUtcToLocal(selectedZone.createdTimestamp)}
              </div>
              {selectedZone &&
                selectedZone.lastModifiedTimestamp?.slice(0, 22) !==
                selectedZone.createdTimestamp?.slice(0, 22) ? (
                <div>
                  <Tooltip title={selectedZone.lastModifiedBy}>
                    <span>
                      {`Zone Modified by ${AppUtils.parseEmailToName(
                        selectedZone.lastModifiedBy
                      )} `}
                    </span>
                  </Tooltip>
                  {selectedZone && parseUtcToLocal(selectedZone.lastModifiedTimestamp)}
                </div>
              ) : null}
            </div>
          </Box>
        </Box>
      </Collapse>
    </TableCell>
  );
}

function getIsAlertPresent(rowData) {
  let isAlertPresent = false;
  let topAlert = null;

  if (rowData.alerts && rowData.alerts.length) {
    isAlertPresent = true;
    topAlert = rowData.alerts[0];
  }
  const borderLeftColorCode = topAlert
    ? ALERT_COLOR_CODES.AlertColor(topAlert.alertType).colorCode
    : "";

  const borderLeftColor = borderLeftColorCode
    ? `rgba(${borderLeftColorCode[0]}, ${borderLeftColorCode[1]}, ${borderLeftColorCode[2]})`
    : null;

  return { isAlertPresent, topAlert, borderLeftColor };
}

function renderAlertTableCell({
  rowData,
  isItemSelected,
  topAlert,
  history,
  classes,
  dispatch,
  Permissions,
}) {
  return (
    <TableCell
      key={`${rowData.id}-collapse-with-alert`}
      colSpan={4}
      style={{
        padding: 0,
      }}
    >
      <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
        <Box
          style={{
            display: "flex",
            order: 0,
            alignItems: "center",
            justifyContent: SPACE_BETWEEN,
          }}
          p={2}
          m={0}
        >
          {topAlert.alertType !== "PlotClear" ? (
            <>
              {Permissions.View_Alerts && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    history.push("/alerts");
                  }}
                  className={classes.alertLink}
                >
                  View Alerts Page
                </Button>
              )}

              {Permissions.Manage_Alerts && (
                <Button
                  variant="filled"
                  className={classes.plotClearBttn}
                  onClick={() => {
                    dispatch(ZonesReducerActions.plotClear(rowData.id));
                  }}
                >
                  Trigger Evacuation
                </Button>
              )}
            </>
          ) : (
            <div className={classes.cardWrapper}>
              <div className={classes.usersInfo}>
                <UsersCount label={"IN ZONE"} count={topAlert.inZoneCount} countColor="#DB4052" />
                <UsersCount
                  label={"IN TRANSIT TO MUSTER POINT"}
                  count={topAlert.inTransitCount}
                  countColor=""
                />
                <UsersCount label={"MARKED SAFE"} count={topAlert.markedSafeCount} countColor="" />
                <UsersCount label={"ERT IN ZONE"} count={topAlert.ertCount} countColor="#13BC86" />
              </div>
              <div className={classes.actions}>
                <div className={classes.lastUpdated}>
                  <div>Last Updated:</div>
                  <div>
                    {topAlert.lastModifiedTimestamp
                      ? parseUtcToLocal(topAlert.lastModifiedTimestamp)
                      : null}
                  </div>
                </div>
                <div>
                  {Permissions.View_Alerts ? (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        history.push("/alerts");
                      }}
                      className={classes.alertsSpan}
                    >
                      View Alerts Page
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </Box>
      </Collapse>
    </TableCell>
  );
}

const _handleTableRowClicked = ({
  rowData,
  onTableRowClickCb,
  dispatch,
  mapEditShapeInProgress,
}) => {
  if (!mapEditShapeInProgress) {
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: rowData,
    });
    onTableRowClickCb && onTableRowClickCb(rowData);
  }
};

function _handleTableRowIconClick({ rowData, isItemSelected, dispatch, onTableExpandBttnCb }) {
  if (!isItemSelected) {
    onTableExpandBttnCb && onTableExpandBttnCb(rowData);
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: rowData,
    });
  } else {
    onTableExpandBttnCb && onTableExpandBttnCb(null);
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: null,
    });
  }
}

function renderCellsCheck({
  rowData,
  tableStyles,
  isAlertPresent,
  topAlert,
  orderBy,
  isLargeMapView,
  history,
  classes,
  borderLeftColor,
}) {

  function setClass() {
    let response;
    if (isAlertPresent) {
      response = `${tableStyles.cell} ${classes.tableCellAlert}`;
    } else {
      response = tableStyles.cell;
    }
    return response;
  }

  return (
    <>
      <TableCell
        className={setClass()}
        align="left"
        style={{
          borderLeftColor: `${borderLeftColor}`,
        }}
        key={`${rowData.id}-color-pallete`}
      >
        <Box display="flex" alignItems="center">
          <Box pr={1.5}>
            <ColorPallete colour={rowData.displayColour} data={rowData} />
          </Box>
          <span>{rowData.name}</span>
        </Box>
      </TableCell>

      <TableCell
        key={`${rowData.id}-${rowData.name}-${rowData.headcount}-count`}
        className={tableStyles.cell}
      >
        {isAlertPresent ? renderAlertChip(topAlert) : `${rowData.headcount} Users`}
      </TableCell>

      {checkToRender("alerts", orderBy) && (
        rowData.alerts.length ? (
          <Tooltip
            arrow
            placement="top"
            classes={{
              tooltip: tableStyles.tooltip,
            }}
            title={
              <>
                <div className={tableStyles.tooltipContentWrapper}>
                  <span className={tableStyles.tooltipContent}>Click to View all Alerts</span>
                </div>
              </>
            }
          >
            <TableCell
              key={`${rowData.id}-${rowData.name}-${rowData.alerts}`}
              className={tableStyles.cell}
              onClick={() => {
                history.push("/alerts");
              }}
            >
              {isLargeMapView ? `Alerts` : ""} {rowData.alerts.length || null}
            </TableCell>
          </Tooltip>
        ) : (
          <TableCell
            className={tableStyles.cell}
            key={`${rowData.id}-${rowData.name}-headcount`}
          ></TableCell>
        )
      )}

      {checkToRender("categories", orderBy) && (
        <TableCell key={`${rowData.id}-${rowData.name}-categories`} className={tableStyles.cell}>
          <div className={classes.chipsWrapperRoot}>{renderChips(rowData)}</div>
        </TableCell>
      )}

      {checkToRender("createdTimestamp", orderBy) && (
        <TableCell
          key={`${rowData.id}-${rowData.name}-${rowData.createdTimestamp}`}
          className={tableStyles.cell}
        >
          <span>
            {topAlert && topAlert.createdTimestamp
              ? parseUtcToLocal(topAlert.createdTimestamp)
              : parseUtcToLocal(rowData.createdTimestamp)}
          </span>
        </TableCell>
      )}
    </>
  );
}

export const Row = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const tableStyles = useCustomTableStyles();
  const { Permissions } = useContext(UserPermissionContext);
  const mapEditShapeInProgress = useSelector((state) => state.zonesScreen.mapEditShapeInProgress);
  const selectedZone = useSelector((state) => state.zonesScreen.selectedZone);
  const smallMapRef = useRef();
  const fullMapRef = useRef();

  const {
    rowData,
    onTableRowClickCb,
    onTableExpandBttnCb,
    isLargeMapView,
    isItemSelected,
    orderBy,
  } = props;

  const { isAlertPresent, topAlert, borderLeftColor } = getIsAlertPresent(rowData);

  useEffect(() => {
    if (!Permissions.View_Alerts) {
      rowData.alerts = [];
      rowData.colour = { red: 238, green: 238, blue: 238 };
      rowData.displayColour = { red: 238, green: 238, blue: 238 };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  useEffect(() => {
    if (isItemSelected && rowData.id !== "000000000000000000000000") {
      if (isLargeMapView) {
        setTimeout(() => {
          fullMapRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
        }, 650);
      } else {
        setTimeout(() => {
          smallMapRef.current.parentElement.parentElement.parentElement.scroll({
            top: smallMapRef.current.offsetTop - 69,
            behavior: "smooth",
          });
        }, 650);
      }
    }
  }, [isLargeMapView, isItemSelected, rowData.id]);

  return (
    <>
      <TableRow
        className={clsx(classes.tableRow, {
          [classes.pointer]: rowData.name !== "{Not in any zone}",
        })}
        classes={{
          selected: classes.selected,
        }}
        hover
        onClick={(event) => {
          event.stopPropagation();
          if (rowData.name === "{Not in any zone}") {
            return;
          }
          _handleTableRowClicked({
            rowData,
            onTableRowClickCb,
            dispatch,
            mapEditShapeInProgress,
          });
        }}
        tabIndex={-1}
        key={`${rowData.id}-firstRow`}
        selected={isItemSelected}
        ref={smallMapRef}
      >
        {renderCellsCheck({
          rowData,
          tableStyles,
          isAlertPresent,
          topAlert,
          orderBy,
          isLargeMapView,
          history,
          classes,
          borderLeftColor,
        })}

        {renderRowActionCell({
          rowData,
          tableStyles,
          mapEditShapeInProgress,
          isItemSelected,
          dispatch,
          onTableExpandBttnCb,
        })}
      </TableRow>
      {!rowData.isFrontEndOnly && (
        <TableRow
          className={classes.tableRow}
          classes={{
            selected: classes.selected,
          }}
          hover
          selected={isItemSelected}
          key={`${rowData.id}-collapsed-row`}
          ref={fullMapRef}
        >
          {isAlertPresent
            ? renderAlertTableCell({
              rowData,
              isItemSelected,
              topAlert,
              history,
              classes,
              dispatch,
              Permissions,
            })
            : renderNonAlertTableCell({ rowData, isItemSelected, selectedZone })}
        </TableRow>
      )}
    </>
  );
};
